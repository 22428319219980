import React, {Component} from "react";

export default function Success(props) {
    return (
        <div>
            <h1>Password Reset</h1>

            <p>Your password has been successfully updated. You may now
            login <a href="/login">here</a></p>
        </div>
    )
};
