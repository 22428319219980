import { createReducer } from "redux-create-reducer";

const initialState = {
    byId: {}
};

export const exhibitionReducer = createReducer(initialState, {
    "FEATURED_EXHIBITIONS": (state, action) => {
        const exhibitionsById = action.featuredExhibitions.reduce((result, exhibition) => {
            // if there is a null slot in the featured exhibitions, ignore it.
            if (!exhibition) {
                return result;
            }

            // NOTE: removed amazonLinks
            result[exhibition._id] = { ...exhibition, amazonLinks: [] };
            return result;
        }, {});

        // merge the new events with the existing...
        const byId = { ...state.byId, ...exhibitionsById };

        return { ...state, byId };
    },
    "GET_EXHIBITION": (state, action) => {    
        // NOTE: removed amazonLinks
        const exhibition = { ...action.data.exhibition, amazonLinks: [] };
        // NOTE: removed amazonLinks
        const museums = Object.entries(action.data.museums || {}).reduce((result, [id, museum]) => {
            result[id] = { ...museum, amazonLinks: []};
            return result;
        }, {});

        return {
            ...state,
            byId: { ...state.byId, [action.data.exhibition._id]: exhibition },
            exhibition: exhibition,
            museums: museums
        };
    }
});
