import { createReducer } from "redux-create-reducer";

const initialState = {};

export const pageReducer = createReducer(initialState, {
    "PAGE": (state, action) => {
        return { ...state, status: 200, title: action.title, content: action.content };
    },
    "PAGE_NOT_FOUND": (state, action) => {
        return { ...state, status: 404 };
    }
});
