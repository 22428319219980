import PropTypes from "prop-types";
import React from "react";

const isNewWindow = (target) => {
    return target === "_blank";
};

/**
 * Return a promise that is resolved when whichever of the following happens first.
 *   a) the given promise is resolved.
 *   b) the duration is exceeded.
 *
 * If the given promise is rejected, the returned promise is rejected.
 *
 * @param promise {Promise} a promise to wrap.
 * @param duration {Number} the number of milliseconds to wait for the promise before resolving anyway.
 * @return {Promise} a Promise that is resolved when the given promise is resolved or a timeout occurs.
 */
const resolveWithin = (promise, duration=1000) => {
    return new Promise((resolve, reject) => {
        // setup a timer, if the given promise is not resolved before this triggers, the promise we have returned
        // will be resolved.
        let handle = setTimeout(function() {
                // null the handle to prevent the original promise then handler from doing anything if the promise
                // is resolved AFTER this timeout has occurred.
                if (handle !== null) {
                    handle = null;
                    resolve();
                }
            }, duration);

        return promise
            .then((value) => {
                // the original promise has resolved, has the timeout already been triggered?
                if (handle !== null) {
                    // no, cancel the timeout, then resolve the promise we have returned..
                    clearTimeout(handle);
                    handle = null;

                    resolve(value);
                }
            })
    });
};

export function OutboundLink(props) {
    var {action, category, label, onClick, track, ...outboundProps} = props;

    outboundProps.onClick = (ev) => {
        // if the browser supports sendBeacon, then we never need to do anything special, otherwise if we're opening
        // the link in a new window, the unload event shouldn't fire so we don't need to wait for the tracking to
        // happen.
        const isLegacy = !navigator.sendBeacon && !isNewWindow(props.target);

        // only stop the browser in its tracks if we're not opening in a new window. If we're not opening in a new
        // window the browser will trigger an `unload` event and our tracking event may not be honoured. We DO want
        // to call `preventDefault` as soon as possible in case something goes wrong.
        isLegacy && ev.preventDefault();

        // issue the tracking event, using the given `category` and `action`
        const promise = track(category, action, label);

        // only if we've invoked `preventDefault` do we need to act on the promise.
        if (isLegacy) {
            // setup a timer to abort waiting if getting a response from GA take too long...
            resolveWithin(promise)
                .then(() => {
                    window.location.href = props.href;
                });
        }

        // now we've at least sent the tracking event, we can execute the original `onClick` handler (if there
        // is one).
        if (onClick) {
            onClick(ev);
        }
    };

    return <a {...outboundProps} />
}

OutboundLink.propTypes = {
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string,
    track: PropTypes.func.isRequired
};
