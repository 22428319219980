import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { MuseumEvent } from "./MuseumEvent";

export const MuseumEvents = ({events}) => {
    return (
        <Fragment>
            <h2 className="hn-exhibition-event-hd" id="exhibitions" name="exhibitions">Exhibitions &amp; Dates</h2>
            <div className="hn-results-list">
                <ul className="list-unstyled row">
                    {events.length === 0 ?
                        <li className="hn-results-li col-sm-6 col-md-4">
                            <div className="alert alert-warning" role="alert">No scheduled events found</div>
                        </li> : null}

                    {events.map(event => <MuseumEvent key={event.eventId} event={event} />)}
                </ul>
            </div>
        </Fragment>
    );
};

MuseumEvents.propTypes = {
    events: PropTypes.array.isRequired
};