import moment from "moment";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router";
import {removeBookmark} from "./BookmarkActions";

export class BookmarksRow extends Component {
    constructor(props) {
        super(props);

        this.onRemove = this.onRemove.bind(this);
    }

    entityUrl(bookmark) {
        if (!bookmark.title) {
            return null;
        }

        switch(bookmark.entityType) {
            case "Exhibition":
                return this.exhibitionUrl(bookmark);
            case "Museum":
                return this.museumUrl(bookmark);
        }
    }

    exhibitionUrl(bookmark) {
        const exhibition = bookmark.entity;
        const url = bookmark.path || `/exhibitions/${bookmark.entityId}`;
        return <Link to={url}>{bookmark.title}</Link>
    }

    museumUrl(bookmark) {
        const url = bookmark.path || `/museums/${bookmark.entityId}`;
        return <Link to={url}>{bookmark.title}</Link>
    }

    onRemove() {
        const {bookmark, onRemove} = this.props;
        onRemove(bookmark._id);
    }

    render() {
        const {bookmark} = this.props;

        return (
            <tr>
                <td className="hn-tb-bookmark-actions"><button className="btn btn-xs btn-danger" onClick={this.onRemove}><i className="fa fa-times"></i></button></td>
                <td className="hn-tb-bookmark-name">{this.entityUrl(bookmark)}</td>
                <td className="hn-tb-bookmark-date">{moment(bookmark.createdDate).fromNow()}</td>
            </tr>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRemove(bookmarkId) {
            dispatch(removeBookmark(bookmarkId));
        }
    };
};

export default connect(null, mapDispatchToProps)(BookmarksRow);
