import React, {Component} from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { ValidationHint, validationState } from "../../../uiw-widgets/LegacyFormValidation";

export class CodeForm extends Component {
    constructor() {
        super()

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(ev) {
        ev.preventDefault();

        let code = findDOMNode(this.refs.code).value;
        this.props.submit(code);
    }

    render() {
        return (
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <FormGroup controlId="code" validationState={validationState(this.props, "code")}>
                    <ControlLabel bsClass="col-sm-2 control-label">Code</ControlLabel>
                    <div className="col-sm-8">
                        <FormControl
                            type="text"
                            ref="code"
                            placeholder="Enter code"
                        />

                        <ValidationHint name="code" fieldErrors={this.props.fieldErrors} />
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className="col-sm-offset-2 col-sm-10">
                        <button type="submit" className="btn btn-primary">Verify Code</button>
                    </div>
                </FormGroup>
            </form>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (code) => {
            dispatch(push("/forgot/code/" + code));
        }
    }
};

const mapStateToProps = (state) => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeForm);
