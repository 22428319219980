const initialState = {
    errors: [],
    fieldErrors: {}
};

export const ERRORS = "MARKETING_ERRORS";
export const REGISTERED = "MARKETING_REGISTERED";
export const REGISTERING = "MARKETING_REGISTERING";
export const RESET = "MARKETING_RESET";

export default function marketingReducer(state = initialState, action) {
    switch (action.type) {
        case ERRORS:
            return Object.assign({}, state, {
                status: "error",
                errors: action.errors || [],
                fieldErrors: action.fieldErrors || {}
            });
        case REGISTERED:
            return Object.assign({}, state, {
                status: "registered",
                errors: [],
                fieldErrors: {},
                registered: {
                    email: action.email,
                    props: action.props
                }
            });
        case REGISTERING:
            return {...state, status: "registering"}
        case RESET:
            return {...initialState}
    }
    return state;
};
