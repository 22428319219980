const initialState = {associations: {}};

export const associationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ASSOCIATIONS":
            const hash = action.associations.reduce((result, assoc) => {
                result[assoc._id] = assoc;
                return result;
            }, {});
            state = {...state, hash};
            break;
    }

    return state;
};
