import classnames from "classnames";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { resultListStyle, resultListLoading, search, mobileFacetDisplay } from "./SearchActions"
import SearchInput from "./SearchInput"
import SearchResultFacets from "./SearchResultFacets"
import { decomposeFacetParams } from "./SearchUtils"
import { MuseumCard } from "../museum/MuseumCard";
import { lookupLocation } from "./SearchActions"

// TODO. import pageSize from SearchActions.
const pageSize = 12;
const resultListClasses = {
    "list": "hn-results-list",
    "grid": "hn-results-grid"
};

export class SearchResults extends Component {

    componentWillReceiveProps(nextProps) {
        var hasSearchChanged = nextProps.location.search !== this.props.location.search,
            hasOffsetChanged = nextProps.resultsOffset != this.props.resultsOffset,
            doSearch = hasSearchChanged || hasOffsetChanged,
            facets, query;

        // we only need to search if the location has changed, this would be the case if the query string has changed
        // or a facet has been selected.
        if (doSearch) {
            query = nextProps.location.query;

            // decompose the query parameters into an object hierarchy representing facets and facet items.
            facets = decomposeFacetParams(query);

            // trigger the search.
            this.props.doSearch({
                what: query.q,
                when: query.when,
                where: query.where,
                facets: facets,
                offset: hasSearchChanged ? 0 : (nextProps.resultsOffset || 0)
            });
        }
    }

    render() {
        var result = this.props.result || {},
            categoryLabels = this.props.categoryLabels || {},
            facets = result.facets || [],
            resultListStyle = this.props.resultListStyle || "list",
            mobileFacetDisplay = this.props.mobileFacetDisplay || "hn-mobile-facet-closed",
            resultListLoading = '',
            viewMore = '';

        if (this.props.resultsListLoading) {
            resultListLoading = <button className="hn-results-action-more btn btn-default btn-block">
                <i className="fa fa-circle-o-notch fa-spin fa-fw"></i> Loading...</button>
        }

        if ((this.props.resultsOffset + pageSize < result.total) && !this.props.resultsListLoading) {
            viewMore = <button className="hn-results-action-more btn btn-default btn-block" onClick={this.props.onViewMore.bind(this, this.props.resultsOffset)}>View More</button>
        }

        return (
            <div className={"row " + mobileFacetDisplay + " " + resultListStyle}>
                <Helmet title="Search Results" />

                <div className="col-sm-4 col-md-3 hn-results-sidebar-filters">
                    <div className="hn-facets-container">
                        <div className="hn-facets-mobile-toolbar visible-xs-block">
                            <button type="button"
                            className="btn btn-default btn-block"
                            onClick={this.props.onmobileFacetDisplayClick.bind(this, "hn-mobile-facet-closed")}>
                            Close Refine
                            </button>
                            <h2><strong>{result.total}</strong> results</h2>
                        </div>
                        <SearchResultFacets categoryLabels={categoryLabels}
                                            facets={facets}
                                            onFacetClick={this.props.onFacetClick}
                                            selected={this.props.facets} />
                    </div>
                </div>
                <div className="col-sm-8 col-md-9 hn-results-main">
                    <SearchInput what={this.props.what}
                                 when={this.props.when}
                                 where={this.props.where}
                                 onLocationLookup={this.props.onLocationLookup}
                                 onSearch={this.props.onSearch} />
                    <div className="hn-facets-mobile-toolbar visible-xs-block">
                        <button type="button"
                        className="btn btn-default btn-block"
                        onClick={this.props.onmobileFacetDisplayClick.bind(this, "hn-mobile-facet-open")}>
                        Refine...
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 hn-results-total">
                            <h2><strong>{result.total}</strong> results</h2>
                        </div>
                        <div className="col-sm-6 hn-results-view-switch">
                            <div className="btn-group">
                                <button type="button" 
                                        className={classnames("btn btn-default", {"active": resultListStyle === "list"})}
                                        onClick={this.props.onResultListStyleClick.bind(this, "list")}>
                                    <i className="fa fa-th-list"></i>
                                </button>
                                <button type="button" 
                                        className={classnames("btn btn-default", {"active": resultListStyle === "grid"})}
                                        onClick={this.props.onResultListStyleClick.bind(this, "grid")}>
                                    <i className="fa fa-th-large"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={resultListClasses[resultListStyle]}>
                        <ul className="list-unstyled row hn-results-row">{this._renderResults()}</ul>
                        {viewMore}
                        {resultListLoading}
                    </div>
                </div>
            </div>
        );
    }

    _renderResults() {
        var result = this.props.result || {},
            categoryLabels = this.props.categoryLabels || {},
            hits = result.hits || [];

        return hits.map((item, idx) => {
            var result = item.document;

            const museum = {
                id: result.museumId,
                heroImage: result.museumHeroImage,
                path: result.museumPath,
                name: result.museumName,
                city: result.museumCity,
                state: result.museumStateName,
                streetAddress: result.museumStreetAddress,
                tags: result.museumTags,
                zipCode: result.museumZipCode
            };

            return (
                <li className="hn-results-li ag-museum-listing col-sm-6 col-md-4 hn-col-xl-3" key={idx}>
                    <MuseumCard museum={museum} />
                </li>
            );
        });
    }
}

const mapStateToProps = function(state, props) {
    var query = props.location.query,
        facets = decomposeFacetParams(query);

    return {
        what: query.q || "",
        when: query.when || "",
        where: query.where || "",
        result: state.search.result,
        resultListStyle: state.search.resultListStyle || "list",
        mobileFacetDisplay: state.search.mobileFacetDisplay || "hn-mobile-facet-closed",
        resultsOffset: state.search.resultsOffset || 0,
        resultsListLoading: state.search.resultsListLoading > 0,
        facets: facets,
        categoryLabels: state.category.categories
    };
};

const mapDispatchToProps = function(dispatch, props) {
    return {
        doSearch: function(opts) {
            // set the "loading" state.
            dispatch(resultListLoading(true));

            dispatch(search(opts))
                .then(function() {
                    // clear the "loading" state.
                    dispatch(resultListLoading(false));
                });
        },

        onLocationLookup: function(q) {
            return dispatch(lookupLocation(q))
                .then(states => {
                    return states;
                });
        },

        onSearch: function(obj) {
            var query = {q: obj.what};

            obj.when && (query.when = obj.when);
            obj.where && (query.where = obj.where);

            // reset the offset to 0.
            dispatch({type: "SEARCH_RESULTS_VIEW_MORE", offset: 0});

            // merge the new query onto the location before pushing.
            let location = Object.assign({}, props.location, {query: query});
            dispatch(push(location));
        },

        onFacetClick: function(facetKey, facetItemKey, ev) {
            let location = props.location,
                query = location.query,
                key = ["fa", facetKey, facetItemKey].join("-");

            if (ev.target.checked) {
                query[key] = null;
            } else {
                delete query[key];
            }

            dispatch(push(location));
        },

        onmobileFacetDisplayClick: function (state) {
            dispatch(mobileFacetDisplay(state));
        },

        onResultListStyleClick: function(style) {
            dispatch(resultListStyle(style))
        },

        onViewMore: function(offset) {
            // trigger the loading of more results.
            // TODO: use action creator.
            dispatch({type: "SEARCH_RESULTS_VIEW_MORE", offset: offset + pageSize});
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
