import warning from "warning";

export const currentEvent = (events, now) => {
    events || (events = []);

    // build a hash of events, keying by `_id`.
    const eventHash = events.reduce((result, event) => {
        warning(event._id, "event is missing an '_id' property");

        result[event._id] = event;
        return result;
    }, {});

    // boil the array of events down to a subset of properies that we're interested in, creating proper Date objects
    // for date values.
    const localEvents = events.map((event) => {
        return {
            id: event._id,
            startDate: event.startDate ? new Date(event.startDate.start) : null,
            endDate: event.endDate ? new Date(event.endDate.end) : null
        }
    });

    // select the current event.
    const currentEvent = localEvents.reduce((result, event) => {
        if ((event.startDate < now) && (!event.endDate || (event.endDate > now))) {
            return event;
        }
        return result;
    }, null);

    // no current event, try and find the next event (the event with the closest starting date to now)
    const nextEvent = !currentEvent && localEvents.reduce((result, event) => {
        // ignore the event if started in the past...
        if (event.startDate < now) {
            return result;
        }

        // we know this starts in the future, if we have no candidate at the moment, we do now.
        if (!result) {
            return event;
        }

        // if the event starts earlier than the current candidate, then we have a new candidate.
        if (event.startDate < result.startDate) {
            return event;
        }

        return result;
    }, null);

    const lastEvent = !nextEvent && (localEvents.length > 0) && localEvents.reduce((result, event) => {
        const date = event.endDate || event.startDate;
        if (date > result.endDate) {
            return event;
        }
        return result;
    });

    const currentLocalEvent = currentEvent || nextEvent || lastEvent;
    return currentLocalEvent ? eventHash[currentLocalEvent.id] : null;
};
