import { readState } from 'history/lib/DOMStateStorage';

const keys = {};

export const customScroll = (prevRouterProps, nextRouterProps) => {
    const {hash, key, pathname, search} = nextRouterProps.location;

    // if the location has any `hash` component, do nothing
    if (hash && (hash[0] === '#')) {
        return hash.substring(1);
    }

    // check `prevRouterProps.location === search AND it's not changed
    if (pathname === "/search") {
        const info = keys[pathname];

        if (info && (info.search === search)) {
            const scrollInfo = readState(`s/${info.key}`);

            // update the key in the pathname record so that future changes to the scroll position are used next time
            // we come through here.
            info.key = key;

            // if we managed to retrieve scrollInfo, return that and the ScrollBehaviour will use it, otherwise return
            // true, which is the default behaviour.
            return scrollInfo || true;
        }

        keys[pathname] = {key, search};
    }

    // default behaviour is to return true;
    return true;
};
