import warning from "warning";
const debug = require("debug")("redux-ga");

/**
 * Test for a JavaScript object.
 *
 * @param value {Any} the value to test.
 * @return {boolean} true if `value` is a JavaScript object.
 */
const isObject = (value) => {
    return (value !== null) && (typeof value === "object") && !Array.isArray(value);
};

/**
 * The google-analytics bootstrap code.
 * Lifted from here -> https://developers.google.com/analytics/devguides/collection/analyticsjs/
 */
const loadGA = () => {
    const url = __DEV__
        ? "https://www.google-analytics.com/analytics_debug.js"
        : "https://www.google-analytics.com/analytics.js";

    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script',url,'ga');
};

/**
 * An action creator for creating a tracker.
 *
 * @param trackingId {String} the tracker id.
 * @param [opts] {Object} the fields object.
 *   @param opts.cookieDomain {String} the cookie domain.
 *   @param opts.name {String} the name of the tracker.
 */
export const createTracker = (trackingId, opts={}) => {
    return {
        type: "@@analytics/createTracker",
        ...opts,
        trackingId
    };
};

/**
 * An action creator for the analytics "event".
 *
 * @param category {String} the event category.
 * @param action {String} the event action.
 * @param [label] {String} the event label.
 * @param [opts] {Object} the options object.
 *   @param opts.eventValue {Number} A numeric value associated with the event.
 */
export const event = (category, action, label, opts = {}) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "@@analytics/event",
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                ...opts,
                hitCallback: resolve
            })
        });
    };
};

/**
 * An action creator for the analytics "pageview" event. This is used to record a page transition event. The tracker
 * should have been updated with the "setPage" action prior to dispatching this action.
 */
export const pageView = () => {
    return {
        type: "@@analytics/pageView"
    }
};

/**
 * Get the tracker when ready.
 *
 * @return {Promise} a promise for the tracker.
 */
export const ready = () => {
    return new Promise((resolve) => {
        ga((tracker) => {
            resolve(tracker);
        });
    });
};

/**
 * Update the tracker with a new "page" value. When the URL in the address bar changes, the tracker must be notified
 * using this action.
 *
 * @param path {Object|String} the location object or the path from the url in the address bar.
 */
export const setPage = (location) => {
    // we got a path or a location object?
    if (isObject(location)) {
        // got a location object, pluck a path and query string out of it to assemble the path we want to send to GA.
        location = `${location.pathname}${location.search}`;
    }

    return {
        type: "@@analytics/setPage",
        path: location
    }
};

/**
 * Create the Google Analytics redux middleware.
 *
 * @param trackingId {String} the Google Analytics tracking id.
 * @return {Function} the redux middleware for the Google Analytics tracker.
 */
export default function(trackingId) {
    return ({dispatch, getState}) => {
        const state = getState();

        // verify that the googleReducer has been wired up.
        const googleState = state.google;
        if (!googleState) {
            warning("expected googleReducer to be mounted under 'google' key");
        }

        // if we were given a trackingId, create the default tracker and set the "transport" to be beacon.
        const trackingId = googleState.analytics && googleState.analytics.trackingId;
        warning(trackingId, "no Google-Analytics tracking-id configured");
        if (trackingId) {
            // only bootstrap GA once.
            if (window && !window.ga) {
                loadGA();
            }

            // use the beacon transport if available.
            ga("create", {trackingId: trackingId});
            ga("set", "transport", "beacon");

            // install the `Enhanced Link Attribution` plugin for Google Analytics. This enables you to differentiate
            // between link clicks on page that have the same href. It uses the `id` property, or the `id` property
            // of an ascendant (by default, up to 3 elements in the tree). Don't forgot to enable `Enhanced Link
            // Attribution` in the Google Analytics account.
            ga("require", "linkid");
        }

        return (next) => {
            return (action) => {
                // if no trackingId is configured, there's no point in carrying on in this middleware, move to the next.
                if (!trackingId) {
                    return next(action);
                }

                const {type, ...props} = action;

                // custom action handlers.
                switch (type) {
                    case "@@analytics/createTracker":
                        ga("create", props);
                        return;
                    case "@@analytics/event":
                        ga("send", {"hitType": "event", ...props});
                        return;
                    case "@@analytics/pageView":
                        ga("send", {"hitType": "pageview"});
                        return;
                    case "@@analytics/setPage":
                        ga("set", "page", action.path)
                        return;
                    case "@@router/LOCATION_CHANGE":
                        dispatch(setPage(action.payload));
                        dispatch(pageView());
                        return next(action);
                }

                // by default, pass along to the next middleware in the chain.
                return next(action);
            }
        }
    }
}
