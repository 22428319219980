import PropTypes from "prop-types";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { byId } from "./PageActions";

export class Page extends Component {
    constructor(props) {
        super(props);

        this.content = this.content.bind(this);
    }

    static propTypes = {
        pageId: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string
    };

    static defaultProps = {
        content: ""
    };

    componentWillReceiveProps(nextProps) {
        // only if the pageId has changed should we load the page... this would typically happen when transitioning
        // between two "static" pages.
        if (this.props.pageId !== nextProps.pageId) {
            this.props.loadPage(nextProps.pageId);
        }
    }

    content() {
        // TODO: run this.props.content through a sanitizer...
        return { __html: this.props.content || "" };
    }

    render() {
        const { pageId, status, title } = this.props;
        if (status === 404) {
            return (
                <div>Page Not Found</div>
            )
        }

        return (
            <div className={`hn-view-static ag-pid-${pageId}`} >
                <Helmet title={title} />

                <div className="jumbotron row">
                    <div className="container">
                        <div className="hn-hero-titles row">
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row hn-rte-content">
                        <div dangerouslySetInnerHTML={this.content()}></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadPage(pageId) {
            return dispatch(byId(pageId));
        }
    }
};

const mapStateToProps = function(state, props) {
    return {
        pageId: props.params.pageId,
        status: state.page.status,
        title: state.page.title,
        content: state.page.content
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
