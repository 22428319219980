import PropTypes from "prop-types";
import React from "react";
import {BookmarkToggle} from "../bookmark";

const ExhibitionBookmarkToggle = (props) => {
    const {exhibition, event, museum} = props;
    if (!exhibition || !exhibition._id) {
        return null;
    }

    if (event && event._id) {
        const entityId = `${exhibition._id}/${event._id}`;
        const museumName = museum && museum.name;
        const title = `${exhibition.title} - ${museumName}`;

        return <BookmarkToggle entityId={entityId} entityType="Exhibition" title={title} />
    }

    return <BookmarkToggle entityId={exhibition._id} entityType="Exhibition" title={exhibition.title} />
};

ExhibitionBookmarkToggle.propTypes = {
    exhibition: PropTypes.object,
    event: PropTypes.object,
    museum: PropTypes.object
};

export default ExhibitionBookmarkToggle;
