import { authFetch } from "../auth";

export function coverage() {
    return (dispatch) => {
        return dispatch(authFetch("/api/museum/coverage"))
            .then((response) => {
                return response.json();
            })
            .then((coverage) => {
                return dispatch({
                        type: "COVERAGE",
                        coverage: coverage
                    });
            });
        };
}

export function fetchedMuseums(museums) {
    return {
        type: "FETCHED_MUSEUMS",
        museums
    };
}

export function getMuseum(id) {
    return (dispatch) => {
        const museumPromise = dispatch(authFetch(`/api/museum/${id}`))
            .then(response => {
                return response.json();
            })
            .then(museum => {
                // bind an "heroImage" property onto each museum, this will be the URL for the hero image for the
                // exhibition.
                if (museum.images && museum.images[0]) {
                    museum.heroImage = `/api/media/${museum.images[0]}`;
                }

                return museum;
            });

        const exhibitionsPromise = dispatch(authFetch(`/api/museum/${id}/currentEvents`))
            .then(response => {
                return response.json();
            });

        return Promise.all([museumPromise, exhibitionsPromise])
            .then(([museum, events]) => {
                return dispatch({
                        type: "GET_MUSEUM",
                        museum,
                        events
                    });
            })
            .catch(err => {
                return dispatch({
                        type: "ERROR",
                        message: err.message,
                        stack: err.stack
                    });
            });
    };
}

/**
 * Get a set of museum records from the array of museum ids passed in.
 *
 * @param museumsIds {Array} an array of museum ids.
 * @return {Promise} a promise for an array of museum records.
 */
export function getMuseums(museumsIds) {
    return (dispatch, getState) => {
        // get a set of UNIQUE museum Ids for the hash.
        let uniqueIds = Object.keys(museumsIds.reduce((ids, id) => {
                id && (ids[id] = true)
                return ids;
            }, {}));

        const queryString = uniqueIds.map(id => `_id=${id}`).join("&");

        return dispatch(authFetch(`/api/museum?limit=0&${queryString}`))
            .then(response => {
                return response.json();
            })
            .then(json => {
                dispatch(fetchedMuseums(json.results));

                const state = getState();
                return json.results.reduce((result, museum) => {
                    result[museum._id] = state.museum.byId[museum._id];
                    return result;
                }, {});
            });
    };
}
