import { createReducer } from "redux-create-reducer";

const initialState = {
    adSense: {
        clientId: null,
        enabled: false
    },
    analytics: {
        key: null
    }
};

export const googleReducer = createReducer(initialState, {});
