import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";
import { Tooltip } from "uiwidgets-react-legacy";
import BookmarkToggle from "./MuseumBookmarkToggle";
import TagGroup from "../tags/TagGroup";

export class MuseumCard extends Component {
    static propTypes = {
        museum: PropTypes.shape({
            city: PropTypes.string,
            heroImage: PropTypes.string,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            state: PropTypes.string,
            streetAddress: PropTypes.string,
            summary: PropTypes.string,
            tags: PropTypes.array,
            zipCode: PropTypes.string
        })
    };

    render() {
        const { museum } = this.props;        
        const href = museum.path || `/museums/${museum.id}`;
        const thumbStyles = {};
        
        if (museum.heroImage) {
            thumbStyles.backgroundImage = `url('${museum.heroImage}')`;
        }

        return (
            <div className="hn-results-content">
                <h4 className="hn-results-summary-hd-bg">
                    <Tooltip>
                        <Link className="hn-result-link" to={href}><i className="fa fa-fw fa-bank" aria-hidden="true"></i>&nbsp;
                            <strong>{museum.name}</strong>
                        </Link>
                    </Tooltip>
                </h4>
                <div className="hn-results-summary">
                    <div className="hn-results-summary-bd">
                        {/*<h5 className="hn-results-date text-muted"></h5>*/}
                        <div className="ag-museum-circle-thumb" style={thumbStyles}></div>
                        <div className="row hn-results-museum-info">
                            <p className="col-xs-7 hn-results-venue">
                                <Tooltip><span>{museum.streetAddress}. {museum.zipCode}</span></Tooltip>
                            </p>
                            <p className="col-xs-5 hn-results-location text-right">
                                <Tooltip><span>{museum.city}, {museum.state}</span></Tooltip>
                            </p>
                        </div>
                        <div className="hn-overview-wrapper">
                            <p className="text-muted hn-overview-summary">
                                {museum.summary}
                            </p>
                            <ul className="list-unstyled hn-list-tags">
                                <TagGroup tags={museum.tags} />
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="hn-results-thumb-container">
                    <div className="hn-results-thumb" style={thumbStyles}></div>
                </div>
                <BookmarkToggle museum={museum} />
            </div>
        );
    }
};