import PropTypes from "prop-types";
import React, {Component} from "react";

/**
 * The AuthContext component exposes the auth status as a context variable to
 * components deeper in the tree. The auth status is seeded from the given
 * provider.
 */
export default class AuthContext extends Component {
    static childContextTypes = {
        auth: PropTypes.object
    }

    getChildContext() {
        let {provider, unauth} = this.props;
        return {
            auth: {provider, components: {unauth}}
        };
    }

    render() {
        // this component has no business in the render pipeline, so we'll
        // just return the child element[s].
        return this.props.children;
    }
}
