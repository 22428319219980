import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "uiwidgets-react-legacy";
import { OutboundLink } from "../../react-redux-ga";

export const ExhibitionOutboundLink = ({href, id, title}) => (
    <p>
        <Tooltip tooltipText="Website opens in a new browser tab">
            <OutboundLink action="Exhibition"
                          category="OutboundLink"
                          className="hn-view-museum-url"
                          href={href}
                          label={`${id} - ${title}`}
                          target="_blank">
                <i className="fa fa-globe fa-fw hn-icon-left"></i> <span className="hn-icon-textblock">Exhibition Website</span>
            </OutboundLink>
        </Tooltip>
    </p>
);

ExhibitionOutboundLink.propTypes = {
    "href": PropTypes.string.isRequired,
    "id": PropTypes.string.isRequired,
    "title": PropTypes.string.isRequired
};
