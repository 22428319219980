import PropTypes from "prop-types";
import React, {Component} from "react";
import { findDOMNode } from "react-dom";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { resetPasswordCode } from "../AuthActions";
import { ValidationHint, required, validationState } from "../../../uiw-widgets/LegacyFormValidation";

export class ResetPassword extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        errors: [],
        fieldErrors: {}
    }

    static propTypes = {
        errors: PropTypes.array,
        fieldErrors: PropTypes.object,
        submit: PropTypes.func.isRequired
    }

    handleSubmit(ev) {
        ev.preventDefault();

        let emailEl = findDOMNode(this.refs.email);
        this.props.submit(emailEl.value);
    }

    render() {
        return (
            <div className="container">
                <Helmet title="Reset Password - ArtGeek" />

                <h1>Reset Password</h1>

                {this.renderAlerts()}

                <div className="alert alert-warning">
                <p>Use this facility to recover your ArtGeek account in the event
                of you forgetting the password. Enter your email address in the
                form below and submit the form to receive an email with further
                    instructions.</p>
                </div>

                <div className="row">
                    <form className="form-horizontal" onSubmit={this.handleSubmit} className="col-sm-8">
                        <FormGroup controlId="email" validationState={validationState(this.props, "email")}>
                            <ControlLabel>Email Address</ControlLabel>
                            <FormControl
                                type="text"
                                ref="email"
                                placeholder="Email Address"
                            />
                            <ValidationHint name="email" fieldErrors={this.props.fieldErrors} />
                        </FormGroup>
                        <FormGroup>
                            <button type="submit" className="btn btn-primary">Reset</button>
                        </FormGroup>
                    </form>
                </div>
            </div>
        );
    }

    renderAlerts() {
        var {errors = []} = this.props;
        if (errors.length) {
            return errors.map((error, idx) => {
                return <Alert key={idx} bsStyle="danger">{error.message}</Alert>
            });
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (email) => {
            var fieldErrors = {},
                data = {email};

            required(fieldErrors, data, "email");

            // clear any pre-existing errors and use the provided fieldErrors.
            dispatch({
                type: "USER_RESET_PASSWORD_STATE",
                fieldErrors
            });

            // if there are fieldErrors, abort, do NOT trigger the action.
            if (Object.keys(fieldErrors).length > 0) {
                return;
            }

            dispatch(resetPasswordCode({email: email.trim()}));
        }
    }
};

const mapStateToProps = (state) => {
    var resetPassword = state.auth.resetPassword || {};
    return {
        errors: resetPassword.errors,
        fieldErrors: resetPassword.fieldErrors
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
