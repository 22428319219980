export default function AuthProfileReducer(state = {}, action) {
    switch(action.type) {
        case "USER_PROFILE":
            state = Object.assign({}, state, {
                model: action.profile
            });
            break;
        case "USER_PROFILE_CHANGE_PASSWORD":
            state = Object.assign({}, state, {
                errors: action.errors,
                fieldErrors: action.fieldErrors
            });
            break;
    }

    return state;
}
