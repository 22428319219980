import PropTypes from "prop-types";
import React, {Component} from "react";
import {Carousel} from "react-bootstrap";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import {Link} from "react-router";
import {push} from "react-router-redux";
import FeaturedExhibitions from "./FeaturedExhibitions";
import ExhibitionCard from "../exhibition/ExhibitionCard";
import SearchInput from "../search/SearchInput";
import {lookupLocation} from "../search/SearchActions";

export class Home extends Component {

    static propTypes = {
        snippets: PropTypes.object.isRequired,
        user: PropTypes.object
    };

    snippet(key) {
        let snippet = this.props.snippets[key] || {};
        // TODO: run this.props.snippets through a sanitizer...
        return {__html: snippet.content || ""};
    }

    render() {
        var categoryLabels = this.props.categoryLabels || {},
            itemSubtitle,
            carouselMuseums = this.props.carouselMuseums || [];

        const meta = [
            {"property": "description", "content": "ArtGeek: Find art exhibitions at museums across USA. Art exhibits from fine arts to folk art. Find museums \“near me.\” Search for US travel ideas. Plan a trip"}
        ];

        return (
            <div>
                <Helmet title="ArtGeek" meta={meta} />

                <div className="hn-carousel-offset-wrapper">
                    <div className="hn-search-hero row">
                        <Carousel interval={20000} className="carousel-fade">
                            {carouselMuseums.map((item, idx) => {
                                return (
                                    <Carousel.Item key={idx}>
                                        <div className="hn-carousel-image" style={{backgroundImage: 'url(' + item.heroImage + ')'}}></div>
                                        <Carousel.Caption>
                                            <Link to={"/museums/" + item._id}>{item.name}</Link>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </div>
                    <div className="container hn-search-wrapper">
                        <SearchInput onSearch={this.props.onSearch}
                                     onLocationLookup={this.props.onLocationLookup} />
                    </div>
                </div>
                <div className={this.props.user ? "ag-user" : ""}>
                    <div className="hn-frontpage-announce-primary">
                        <div className="container hn-rte-content">
                            <div className="hn-frontpage-announce-content" dangerouslySetInnerHTML={this.snippet('topSection')}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container hn-results-grid">
                    <div className="hn-featured-heading">
                        <h2>Editors' picks</h2>
                    </div>
                    <ul className="list-unstyled row">
                        <FeaturedExhibitions />
                    </ul>
                </div>
                {this.props.children}
                <div className="hn-frontpage-announce-secondary">
                    <div className="container hn-rte-content">
                        <div className="hn-frontpage-announce-content" dangerouslySetInnerHTML={this.snippet('lowerSection')}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categoryLabels: state.category.categories,
        carouselMuseums: state.home.carousel,
        snippets: state.home.snippets || {},
        user: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        "onLocationLookup": function(q) {
            return dispatch(lookupLocation(q))
                .then(function(states) {
                    return states;
                });
        },
        "onSearch": function(obj) {
            var query = {q: obj.what};

            obj.when && (query.when = obj.when);
            obj.where && (query.where = obj.where);

            dispatch(push({pathname: "/search", query: query}));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
