import React from "react";
import {ListGroup} from "react-bootstrap";
import SearchResultFacetValue from "./SearchResultFacetValue";
import {MAX_FACETS, hasSelectedChildren} from "./SearchResultFacets";

export const intoBuckets = (facets, selected, facetKey) => {
    const visible = facets.slice(0, MAX_FACETS);
    const extraVisible = [];
    const remaining = facets.slice(MAX_FACETS);
    const others = [];

    // if the facetKey has no entry in the selected hash, none of the facetItems can be selected, so we can shortcut
    // the checking and just assemble the appropriate arrays.
    if (selected[facetKey]) {
        for (const facetItem of remaining) {
            const isSelected = selected[facetKey][facetItem.key];
            if (isSelected || hasSelectedChildren(selected[facetKey], facetItem.key)) {
                extraVisible.push(facetItem);
            } else {
                others.push(facetItem);
            }
        }
    }

    return {visible, extraVisible, others};
};

const CollapsedSearchResultFacetGroup = (props) => {
    const {categoryLabels, facetKey, facets, onFacetClick, selected} = props;

    const {visible, extraVisible, others} = intoBuckets(facets, selected, facetKey);
    return (
        <div>
            <ListGroup>
                {visible.map((facetItem, idx) => {
                    return <SearchResultFacetValue key={idx}
                                                   categoryLabels={categoryLabels}
                                                   facetKey={facetKey}
                                                   facetItem={facetItem}
                                                   onFacetClick={onFacetClick}
                                                   selected={selected} />
                })}
            </ListGroup>
            <ListGroup className="hn-facet-extra">
                {extraVisible.map((facetItem, idx) => {
                    return <SearchResultFacetValue key={idx}
                                                   categoryLabels={categoryLabels}
                                                   facetKey={facetKey}
                                                   facetItem={facetItem}
                                                   onFacetClick={onFacetClick}
                                                   selected={selected} />
                })}
            </ListGroup>
            <ListGroup className="hn-facet-hidden">
                {others.map((facetItem, idx) => {
                    return <SearchResultFacetValue key={idx}
                                                   categoryLabels={categoryLabels}
                                                   facetKey={facetKey}
                                                   facetItem={facetItem}
                                                   onFacetClick={onFacetClick}
                                                   selected={selected} />
                })}
            </ListGroup>
        </div>
    );
};

export default CollapsedSearchResultFacetGroup;
