import React, {Component} from "react"

export default class TagGroup extends Component {
    render() {
        var {categoryLabels = {}, categories = [], tags = [], artists = []} = this.props;
        return (
            <ul className="list-unstyled hn-list-tags">
                {categories.map((category, idx) => {
                    var topLevelCategory = category.split("/", 1),
                        tagClass = "hn-tag-cat-" + topLevelCategory,
                        label = categoryLabels[category] || category;

                    return <li className={"label label-default hn-tag-category " + tagClass} key={idx}>{label}</li>
                })}
                {tags.map((tag, idx) => {
                    return <li className="label label-default hn-tag-misc" key={idx}>{tag}</li>
                })}
                {artists.map((artist, idx) => {
                    return <li className="label label-default hn-tag-artist" key={idx}>{artist.fullName}</li>
                })}
            </ul>
        )        
    }
}
