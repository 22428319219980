export default function syncAuthProvider(authProvider, store) {
    // if authenticated, initialise the store with the user session.
    if (authProvider.isAuthenticated()) {
        let token = authProvider.token();
        let payload = authProvider.payload();
        store.dispatch({
                type: "@@auth/LOGIN",
                token: token,
                user: payload.user
            });
    }

    // hook a listener to update the redux store with changes from the
    // authProvider.
    authProvider.addListener((ev) => {
        switch (ev.type) {
            case "login":
                let payload = authProvider.payload();
                store.dispatch({
                        type: "@@auth/LOGIN",
                        token: ev.token,
                        user: payload.user,
                    });
                break;
            case "logout":
                store.dispatch({
                    type: "@@auth/LOGOUT"
                });
                break;

        }
    });
}
