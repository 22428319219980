import React, {Component} from "react";

export default function Success(props) {
    return (
        <div>
            <h1>Verification - Success</h1>

            <p>Your user account has now been verfied</p>
        </div>
    )
};
