import classnames from "classnames";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Tooltip} from "uiwidgets-react-legacy";
import {createBookmark, removeBookmark} from "../bookmark";

export class BookmarkToggle extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    static propTypes = {
        bookmarkId: PropTypes.string,
        entityId: PropTypes.string,
        entityType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        user: PropTypes.object
    };

    toggle() {
        const {bookmarkId, createBookmark, removeBookmark, user} = this.props;

        if (!user) {
            return;
        }

        if (bookmarkId) {
            removeBookmark(bookmarkId);
        } else {
            createBookmark(user._id);
        }
    }

    render() {
        const {bookmarkId, user} = this.props;
        const tooltipText = user ? (bookmarkId ? "Remove Bookmark" : "Add Bookmark") : "Login to Bookmark";
        const buttonText = bookmarkId ? "Remove Bookmark" : "Add Bookmark";
        const classes = classnames("hn-results-fav btn btn-link", {
                "bookmarked": !!bookmarkId,
                "disabled": !user
            });

        return (
            <Tooltip tooltipText={tooltipText}>
                <button className={classes} role="button" onClick={this.toggle}>
                    <i className="fa fa-bookmark fa-fw"></i> <span>{buttonText}</span>
                </button>
            </Tooltip>
        );
    }
};

const mapStateToProps = (state, ownProps) => {
    const {entityId, entityType} = ownProps;
    const bookmarkedEntities = state.bookmark.entityTypes[entityType] || {};
    return {
        bookmarkId: bookmarkedEntities[entityId] || null,
        user: state.auth.user
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createBookmark(userId) {
            const {entityId, entityType, title} = ownProps;
            return dispatch(createBookmark(userId, entityType, entityId, title));
        },
        removeBookmark(bookmarkId) {
            return dispatch(removeBookmark(bookmarkId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkToggle);
