import React from "react";
import {findDOMNode} from "react-dom";
import {Alert, Button, FormControl, FormGroup, InputGroup} from "react-bootstrap";
import {connect} from "react-redux";
import {errors, register, registered, reset} from "../marketing";
import {ValidationHint, validationState} from "../../uiw-widgets/LegacyFormValidation";

/**
 * Render a snippet to enable newsletter registration.
 *
 * @param props {Object} the properties
 *   @param props.fieldErrors {Object} a hash of field errors, keyed by field key.
 *   @param props.status {String} the registration status one of ["registered"].
 */
export function NewsletterForm(props) {
    var emailComponent;

    /**
     * Handle for the form submission. This should prevent the default browser behaviour and then take the email
     * address value from the form and sumbit it to the registration process.
     */
    const registerNewsletter = (ev) => {
        // prevent default browser form behaviour.
        ev.preventDefault();

        // find the input and send off the given email address using `doRegister`.
        const emailEl = findDOMNode(emailComponent);
        props.doRegister(emailEl.value);
    };

    return (
        <div>
            <h3>Newsletter</h3>

            <SuccessAlert status={props.status} />

            <form onSubmit={registerNewsletter}>
                <FormGroup controlId="newsletter-email" validationState={validationState(props, "newsletter-email")}>
                    <InputGroup>
                        <FormControl type="email"
                                     placeholder="Enter email..."
                                     ref={(ref) => {emailComponent = ref;}} />
                                 <InputGroup.Button><Button type="submit">Subscribe</Button></InputGroup.Button>
                    </InputGroup>
                    <ValidationHint name="newsletter-email" fieldErrors={props.fieldErrors} />
                </FormGroup>
            </form>
        </div>
    );
}

NewsletterForm.defaultProps = {
    fieldErrors: {}
};

/**
 * Conditionally display a success alert when the registration is complete.
 *
 * @param props {Object} the properties.
 *   @param props.status {String} the registration status one of ["registered"].
 */
function SuccessAlert(props) {
    if (props.status !== "registered") {
        return null;
    }

    return <Alert bsStyle="success">You have registered to receive the newsletter</Alert>
}

const mapDispatchToProps = (dispatch) => {
    return {
        doRegister: (email) => {
            dispatch(register(email))
                .then((json) => {
                    // dispatch an action to show the "success" alert.
                    dispatch(registered(json.email, {}));

                    // leave the alert visible for about 5s and then reset the form state.
                    setTimeout(() => {
                        dispatch(reset());
                    }, 5000);
                })
                .catch((err) => {
                    dispatch(errors({
                            "fieldErrors": {
                                "newsletter-email": {
                                    "state": "error",
                                    "message": err.message
                                }
                            }
                        }));
                });
        }
    }
};

const mapStateToProps = (state) => {
    return {
        status: state.marketing.status,
        fieldErrors: state.marketing.fieldErrors
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterForm);
