import Bookmarks from "./Bookmarks";
import {fetchBookmarks} from "./BookmarkActions";
import {withPermission} from "../auth";

export const createRoutes = (store) => {
    return [{
        indexRoute: {
            component: withPermission(Bookmarks)
        },
        onEnter: (nextState, replace, callback) => {
            const state = store.getState();
            if (state.auth.user) {
                return store.dispatch(fetchBookmarks())
                    .then(() => {
                        callback();
                    });
            }

            callback();
        }
    }];
};
