import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router";
import {buildSearchQueryString} from "./SearchUtils";

export const SearchResultsButton = (props) => {
    if (!props.query || (Object.keys(props.query).length === 0)) {
        return null;
    }

    const url = "/search?" + buildSearchQueryString(props.query);
    return (
        <Link className="btn btn-link" to={url}>{props.children}</Link>
    )
};

SearchResultsButton.propTypes = {
    query: PropTypes.object
};

const mapStateToProps = (state) => {
    const {result = {}} = state.search;
    return {
        query: result.query
    };
}

export default connect(mapStateToProps)(SearchResultsButton);
