import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

export class GoogleAdsenseProvider extends Component {
    static propTypes = {
        clientId: PropTypes.string.isRequired,
        enabled: PropTypes.bool,
        pageLevelAds: PropTypes.bool
    };

    static defaultProps = {
        enabled: true,
        pageLevelAds: true
    };

    componentDidMount() {
        const { clientId, enabled, pageLevelAds } = this.props;
        const adsByGoogle = (window.adsbygoogle = window.adsbygoogle || []);

        adsByGoogle.push({
            google_ad_client: clientId,
            enable_page_level_ads: pageLevelAds
        });

        enabled && this.insertScript();
    }

    insertScript() {
        const scriptEl = document.createElement("script");

        scriptEl.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        scriptEl.async = true;

        document.body.appendChild(scriptEl);
    }

    render() {
        return this.props.children;
    }
};

const mapStateToProps = (state) => {
    return {
        clientId: state.google.adSense.clientId,
        enabled: !!state.google.adSense.enabled
    };
};

export default connect(mapStateToProps)(GoogleAdsenseProvider);
