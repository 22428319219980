import React from "react";
import {connect} from "react-redux";
import {login} from "./AuthActions";
import {Login} from "./Login";

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password) => {
            return dispatch(login(username, password));
        },
        loginSuccess: () => {
            // no-op
        }
    }
};
const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
