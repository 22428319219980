import {authFetch} from "../auth";

export const fetchBookmarks = () => {
    return (dispatch) => {
        return dispatch(authFetch("/api/bookmark?limit=0"))
            .then((response) => {
                return response.json();
            })
            .then((body) => {
                dispatch(bookmarks(body.results));
                return body.results;
            });
    };
};

export const bookmark = (bookmark) => {
    return {
        type: "BOOKMARK",
        bookmark
    };
}

export const bookmarks = (bookmarks) => {
    return {
        type: "BOOKMARKS",
        bookmarks: bookmarks
    }
}

export const clearBookmarks = () => {
    return {type: "CLEAR_BOOKMARKS"}
}

export const unbookmark = (bookmark) => {
    return {
        type: "UNBOOKMARK",
        bookmark
    }
}

export const createBookmark = (userId, entityType, entityId, title) => {
    return (dispatch) => {
        var opts = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({userId, entityType, entityId, title})
            };

        return dispatch(authFetch("/api/bookmark", opts))
            .then((response) => {
                return response.json();
            })
            .then((body) => {
                dispatch(bookmark(body));
            });
    };
};

export const removeBookmark = (bookmarkId) => {
    return (dispatch) => {
        var opts = {
                method: "DELETE"
            };

        return dispatch(authFetch(`/api/bookmark/${bookmarkId}`, opts))
            .then((response) => {
                return response.json();
            })
            .then((body) => {
                dispatch(unbookmark(body));
            });
    };
};
