import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class ProfileSection extends Component {
    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeClick = this.onChangeClick.bind(this);
    }

    static propTypes = {
        heading: PropTypes.string.isRequired,
        helpText: PropTypes.string.isRequired,
        active: PropTypes.bool,
        id: PropTypes.string,
        setActive: PropTypes.func
    };

    static defaultProps = {
        open: false
    };

    onCancelClick() {
        const {setActive} = this.props;

        setActive && setActive(null);
    }

    onChangeClick() {
        const {active, id, setActive} = this.props;

        setActive && setActive(active ? null : id);
    }

    render() {
        const {children, heading, helpText, active} = this.props;

        return (
            <fieldset className="container hn-accord panel panel-default">
                <div className="hn-accord-heading panel-heading row">
                    <div className="col-sm-offset-2 col-sm-6">
                        <h4>{heading}</h4>
                        <p className="help-block">{helpText}</p>
                    </div>
                    <div className="col-sm-2 text-right">
                        <button type="button" className="btn btn-default" onClick={this.onChangeClick}>Change</button>
                    </div>
                </div>
                <div className={classnames("panel-collapse collapse", {"in": active})}>
                    <div className="hn-accord-body panel-body">
                        {children}
                    </div>
                </div>
            </fieldset>
        );
    }
};
