import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Footer, Header } from "./home";

export const ErrorPage = (props) => {
    return (
        <Fragment>
            <Header />
            <div className="container-fluid ag-error-room">
                <div className="ag-site-error-frame"></div>
                <h1 className="text-center text-danger">Oops something broke!</h1>
                <p className="text-center"><a href="/" className="btn btn-sm btn-danger">Try going back to the homepage</a></p>
                <div className="ag-error-sticker"></div>
                <div className="ag-error-base"></div>
            </div>
            <Footer />
        </Fragment>
    )
};

ErrorPage.propTypes = {
    "error": PropTypes.string,
    "info": PropTypes.string
};

export default ErrorPage;
