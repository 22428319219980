const initialState = {entityTypes: {}, list: []};

const sortByDate = (a, b) => {
    if (a.createdDate < b.createdDate) return 1
    if (a.createdDate > b.createdDate) return -1
    return 0
};

export const bookmarkReducer = (state = initialState, action) => {
    switch (action.type) {
        case "BOOKMARK":
            (() => {
                const {bookmark} = action;
                const {entityTypes} = state;
                const bookmarks = entityTypes[bookmark.entityType] || (entityTypes[bookmark.entityType] = {});
                bookmarks[bookmark.entityId] = bookmark._id;
                state.list.unshift(bookmark);

                state = {...state};
            })();
            break;
        case "BOOKMARKS":
            (() => {
                const entityTypes = action.bookmarks.reduce((types, bookmark) => {
                    const bookmarks = types[bookmark.entityType] || (types[bookmark.entityType] = {});
                    bookmarks[bookmark.entityId] = bookmark._id;
                    return types;
                }, {});

                state = {...state, entityTypes, list: action.bookmarks.sort(sortByDate)};
            })();
            break;
        case "CLEAR_BOOKMARKS":
            (() => {
                state = {...state, list: [], entityTypes: {}}
            })();
            break;
        case "UNBOOKMARK":
            (() => {
                const {bookmark} = action;
                const {entityTypes, list} = state;
                const bookmarks = entityTypes[bookmark.entityType];
                if (bookmarks) {
                    delete bookmarks[bookmark.entityId];
                }

                const index = list.findIndex((item) => { return item._id == bookmark._id; });
                if (index != -1) {
                    list.splice(index, 1);
                }
                state = {...state, list: [...list]};
            })();
            break;
    }

    return state;
};
