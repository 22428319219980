import { createReducer } from "redux-create-reducer";

const initialState = {
    byId: {}
};

export const eventReducer = createReducer(initialState, {
    "FEATURED_EXHIBITIONS": (state, action) => {
        const eventsById = action.featuredExhibitions.reduce((result, feature) => {
            // if there is a null slot in the featured exhibitions, ignore it.
            if (!feature || feature.type !== "exhibition") {
                return result;
            }

            for (let event of feature.events) {
                result[event._id] = {
                    id: event._id,
                    exhibitionId: feature.id,
                    museumId: event.museumId,
                    startDate: event.startDate,
                    endDate: event.endDate
                };
            }

            return result;
        }, {});

        // merge the new events with the existing...
        const byId = { ...state.byId, ...eventsById };

        return { ...state, byId };
    },
    "GET_EXHIBITION": (state, action) => {
        const { exhibition } = action.data;
        const eventsById = (exhibition.events || []).reduce((result, event) => {
            result[event._id] = {
                id: event._id,
                exhibitionId: exhibition._id,
                museumId: event.museumId,
                startDate: event.startDate,
                endDate: event.endDate
            };

            return result;
        }, {});

        // merge the new events with the existing...
        const byId = { ...state.byId, ...eventsById };

        return { ...state, byId };
    },
    "GET_MUSEUM": (state, action) => {
        // extract the events from the action.
        const eventsById = (action.events || []).reduce((result, event) => {
            result[event.eventId] = {
                id: event.eventId,
                exhibitionId: event.exhibitionId,
                museumId: action.museum._id,
                startDate: event.startDate,
                endDate: event.endDate
            };

            return result;
        }, {});

        // merge the new events with the existing...
        const byId = { ...state.byId, ...eventsById };

        return { ...state, byId };
    }
});
