import { createReducer } from "redux-create-reducer";

const initialState = {
    carousel: [],
    errorNotificationsEnabled: false,
    featured: [],
    snippets: {}
};

export const homeReducer = createReducer(initialState, {
    "CAROUSEL_MUSEUMS": (state, action) => {
        return {...state, carousel: action.data.museums};
    },
    "FEATURED_EXHIBITIONS": (state, action) => {
        return {...state, featured: action.featuredExhibitions.map(feature => {
            if (!feature) {
                return null;
            }
            return {
                type: feature.type,
                id: feature.id
            };
        })};
    },
    "HOME_SNIPPETS": (state, action) => {
        return {...state, snippets: action.data};
    }
});
