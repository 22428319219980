let window = global.window;

// atob polyfill for the node environment.
const atob = window ? window.atob : function(base64) {
    return new Buffer(base64, "base64").toString("binary");
};

/**
 * Extract the payload from the JWT.
 *
 * @param token {String} the JWT.
 * @returns {Object} the payload from the JWT.
 */
export function extractPayload(token) {
    var parts = token.split("."),
        result;

    // there is only a payload if we have 3 parts.
    if (parts.length === 3) {
        try {
            let base64 = parts[1].replace(/-/g, "+").replace(/_/g, "/");
            result = JSON.parse(decodeURIComponent(escape(atob(base64))));
        } catch(e) {
            // do nothing, fall through to return undefined.
        }
    }

    return result;
}

/**
 * Test if the token is expired, by looking at the "exp" claim in the JWT payload.
 *
 * @param payload {Object} the JWT payload.
 * @returns {boolean} true if the token has expired.
 */
export function hasExpired(payload) {
    // if there is no expiration (exp) claim in the payload, then the token will not have expired.
    if (!payload.exp) {
        return false;
    }

    // The "exp" claim appears to be in seconds, not milliseconds.
    let now = Math.floor(Date.now() / 1000);

    // has the token expired?
    return (payload.exp < now);
}
