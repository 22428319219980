/**
 * Test if we're operating in a browser environment.
 *
 * @return {boolean} true if we are, false if we're not.
 */
const isBrowser = () => {
    return typeof window !== "undefined";
};

export default isBrowser;
