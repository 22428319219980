import classnames from "classnames";
import React, {PureComponent} from "react";

export default class Checkbox extends PureComponent {
    constructor(props) {
        super(props);

        this.setRef = this.setRef.bind(this);
    }

    componentDidMount() {
        const {indeterminate} = this.props;
        this.ref.indeterminate = !!indeterminate;
    }

    componentWillReceiveProps(nextProps) {
        // the `indeterminate` property can only be set on the DOM object, there is no property we can set on the
        // input component.
        this.ref.indeterminate = !!nextProps.indeterminate;

        // update the `checked` property on the DOM object to reflect the state change, as we're using an uncontrolled
        // input checkbox.
        this.ref.checked = !!nextProps.checked;
    }

    setRef(ref) {
        this.ref = ref;
    }

    render() {
        const {checked, children, className, indeterminate, inline, onChange, value} = this.props;

        const labelClasses = inline ? classnames(className, {"checkbox-inline": true}) : undefined;
        const checkboxClasses = classnames({"uiw-indeterminate": indeterminate});

        // using an uncontrolled component rather than a controlled component. It seems a controlled input does not
        // work so well with a React.PureComponent as changes to the state do not always persist.
        const content = (
            <label className={labelClasses}>
                <input type="checkbox"
                       className={checkboxClasses}
                       ref={this.setRef}
                       value={value}
                       defaultChecked={checked}
                       onChange={onChange} />
                <span />
                {children}
            </label>
        );

        if (inline) {
            return content;
        }

        const divClasses = classnames(className, {checkbox: true});
        return <div className={divClasses}>{content}</div>;
    }
}
