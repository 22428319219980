import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, applyRouterMiddleware, browserHistory, match } from "react-router";
import { routerMiddleware, syncHistoryWithStore } from "react-router-redux";
import { useScroll } from "react-router-scroll";
import { createStore, applyMiddleware, compose } from "redux";
import analytics from "./redux-ga";
import thunk from "redux-thunk";
import { createRoutes } from "./routes";
import reducers from "./reducers";
import browserJwtTokenStrategy from "./components/auth/AuthClientToken";
import { clearBookmarks, fetchBookmarks } from "./components/bookmark";
import { AuthContext, Login, authMiddleware, jwtAuthProvider, syncAuthProvider } from "./components/auth";
import {customScroll} from "./customScroll";

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'uiwidgets-react-calendar/src/calendar.css';
import 'uiwidgets-react-datepicker/src/datepicker.css';
import './uiw-widgets/scrolltotop/scrolltotop.css';
import './css/theme.css';
import './css/skin.css';

const { ...initState } = window.__initialState__;
const authProvider = jwtAuthProvider(browserJwtTokenStrategy);

// setup the Redux middlware.
let middleware = applyMiddleware(thunk, analytics(), authMiddleware(authProvider), routerMiddleware(browserHistory));
if (__DEV__) {
    // if __DEV__ include the hook for the Redux devToolsExtension (if available)
    middleware = compose(middleware, window.devToolsExtension ? window.devToolsExtension() : f => f);
}

const store = createStore(reducers(), initState, middleware);

// override history.listen to pass location updates through the store first.
const history = syncHistoryWithStore(browserHistory, store);
syncAuthProvider(authProvider, store);

// create the routes object, passing the store to make dispatch available to any route hooks.
const routes = createRoutes(store);

if (authProvider.isAuthenticated()) {
    store.dispatch(fetchBookmarks());

}
authProvider.addListener((ev) => {
    if (ev.type === "login") {
        store.dispatch(fetchBookmarks());
    } else if (ev.type === "logout") {
        store.dispatch(clearBookmarks());
    }
});

// as some of our routes maybe async, we need to pre-match the current location (the browserHistory will provide this).
// and then pass the renderProps to the Router. This will resolve some of the initial diff issues when using server-side
// rendering.
match({history, routes}, (err, redirectLocation, renderProps) => {
    // renderProps has been populated with all the components/routes, even async ones, so we can just pass them
    // straight through to the Router.
    // TODO: use ReactDOM.render for clientOnly renders.
    ReactDOM.hydrate(
        <Provider store={store}>
            <AuthContext provider={authProvider}>
                <Router {...renderProps} render={applyRouterMiddleware(useScroll(customScroll))} />
            </AuthContext>
        </Provider>,
        document.getElementById("app")
    );
});

if (!document.body.hasAttribute("data-init-state")) {
    console.log("enabling init-state");
    document.body.setAttribute("data-init-state", "");
}
