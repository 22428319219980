import classnames from "classnames";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router";
import {format} from "uiwidgets-datetime";
import {Tooltip} from "uiwidgets-react-legacy";
import BookmarkToggle from "./ExhibitionBookmarkToggle";
import TagGroup from "../tags/TagGroup";

const findImage = (exhibition, event) => {
    // first try and locate an image from the museum for the given event (if there is one).
    let image = event && event.museum && event.museum.heroImage;
    return image;
}

const getExhibitionHref = (exhibition, event) => {
    if (event._id) {
        return event.path || `/exhibitions/${exhibition._id}/${event._id}`;
    }
    return exhibition.path || `/exhibitions/${exhibition._id}`;
}

export class ExhibitionCard extends Component {
    render() {
        var exhibition = this.props.exhibition || {},
            categoryLabels = this.props.categoryLabels || {},
            event = this.props.event || {},
            museum = event.museum || {},
            artists = exhibition.artists || [],
            tags = exhibition.tags || [],
            categories = exhibition.categories || [],
            exhibitionHref = getExhibitionHref(exhibition, event),
            museumHref = museum.path || ("/museums/" + museum._id),
            image = findImage(exhibition, event),
            thumbStyles = {},
            exhibitionSubtitle;

        if (image) {
            thumbStyles.backgroundImage = "url('" + image + "')";
        }

        if (exhibition.subTitle) {
            exhibitionSubtitle = <span> &nbsp;<span className="sr-only">-</span> {exhibition.subTitle}</span>
        }

        return (
            <div className="hn-results-content">
                <h4 className="hn-results-summary-hd-bg">
                    <Tooltip><Link className="hn-result-link" to={exhibitionHref}><strong>{exhibition.title}</strong> {exhibitionSubtitle}</Link></Tooltip>
                </h4>
                <div className="hn-results-summary">
                    <div className="hn-results-summary-bd">
                        <h5 className="hn-results-date text-muted">
                            <span className="hn-results-date-start">{format(event.startDate, {utc: true})}</span>
                            <span className="hn-results-date-dash"> - </span>
                            <span className="hn-results-date-end">{format(event.endDate, {utc: true})}</span>
                        </h5>
                        <div className="row hn-results-museum-info">
                            <p className="col-xs-7 hn-results-venue">
                                <Link to={museumHref}>
                                    <Tooltip><span>{museum.name}</span></Tooltip>
                                </Link>
                            </p>
                            <p className="col-xs-5 hn-results-location text-right">
                                <Tooltip><span>{museum.city}, {museum.state}</span></Tooltip>
                            </p>
                        </div>
                        <div className="hn-overview-wrapper">
                            <p className="text-muted hn-overview-summary">
                                {exhibition.summary}
                            </p>
                            <ul className="list-unstyled hn-list-tags">
                                <TagGroup categories={categories}
                                          categoryLabels={categoryLabels}
                                          artists={artists}
                                          tags={tags} />
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="hn-results-thumb-container">
                    <div className="hn-results-thumb" style={thumbStyles}></div>
                </div>
                <BookmarkToggle exhibition={exhibition} event={event} museum={museum} />
            </div>
        );
    }
}

ExhibitionCard.propTypes = {
    categoryLabels: PropTypes.object.isRequired,
    exhibition: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        path: PropTypes.string,
        artists: PropTypes.array,
        categories: PropTypes.array,
        heroImage: PropTypes.string,
        tags: PropTypes.array,
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string,
        summary: PropTypes.summary
    }),
    event: PropTypes.shape({
        endDate: PropTypes.object,
        museum: PropTypes.shape({
            _id: PropTypes.string,
            name: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string
        }),
        startDate: PropTypes.object
    })
};

export default ExhibitionCard;
