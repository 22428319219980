import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { associationsReducer } from "./components/assoc";
import AuthProfileReducer from "./components/auth/AuthProfileReducer"
import AuthReducer from "./components/auth/AuthReducer";
import { categoryReducer } from "./components/categories/CategoryReducer";
import { eventReducer } from "./components/event/EventReducer";
import { exhibitionReducer } from "./components/exhibition/ExhibitionReducer";
import { bookmarkReducer } from "./components/bookmark";
import { homeReducer } from "./components/home/homeReducer";
import { marketingReducer } from "./components/marketing";
import { museumReducer } from "./components/museum/MuseumReducer";
import { pageReducer } from "./components/page/PageReducer";
import SearchReducer from "./components/search/SearchReducer";
import { googleReducer } from "./google/googleReducer";

/**
 * A common set of reducers for inclusion on both the client and server.
 */
export default function(reducers={}) {
    Object.assign(reducers, {
        "auth": AuthReducer,
        "assoc": associationsReducer,
        "bookmark": bookmarkReducer,
        "category": categoryReducer,
        "event": eventReducer,
        "exhibition": exhibitionReducer,
        "google": googleReducer,
        "home": homeReducer,
        "marketing": marketingReducer,
        "museum": museumReducer,
        "page": pageReducer,
        "profile": AuthProfileReducer,
        "search": SearchReducer,
        "routing": routerReducer
    });
    return combineReducers(reducers);
}
