import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "../../uiw-widgets/uiwidgets-react-select";

export default class SearchInput extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        what: PropTypes.string,
        where: PropTypes.string,
        onLocationLookup: PropTypes.func,
        onSearch: PropTypes.func
    };

    onSearch(ev) {
        ev.preventDefault();

        const { where } = this.refs;
        const obj = {where: where.refs.input.value};

        if (!obj.where) {
            // TODO: notification that you *must* enter something.
            console.log("ERROR: no search info");
            return;
        }

        this.props.onSearch && this.props.onSearch(obj);
    }

    onWhenTextChange(whenText) {
        this.setState({whenText});
    }

    render() {
        return (
            <div className="hn-search-main-inputs">
                <form onSubmit={(ev) => {this.onSearch(ev)}}>
                    <div className="uiw-select-inputgroup input-group-lg">
                        <div style={{display: "table-cell"}}>
                            <Select className="input-lg"
                                    defaultValue={this.props.where}
                                    minQueryLength={2}
                                    onLookup={this.props.onLocationLookup}
                                    placeholder="Enter a US city, state or museum"
                                    ref="where" />
                        </div>
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="submit">Search</button>
                        </span>
                    </div>
                </form>
            </div>
        );
    }
}
