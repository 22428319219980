const initialState = {
    resultListStyle: "grid"
};

export default function SearchReducer(state = initialState, action) {
    switch (action.type) {
        case "SEARCH_RESULTS_VIEW_MORE":
            state = Object.assign({}, state, {resultsOffset: action.offset});
            break;
        case "SEARCH_RESULTS":
            let query = action.result.query || {};
            let result;
            if (action.offset > 0) {
                // merge hits into current results.
                result = state.result;

                let stateHits = result.hits;
                let args = [action.offset, 0].concat(action.result.hits);

                // use splice to insert the elements into the array. NOTE: if offset > length of array, then it will
                // just append them.
                stateHits.splice.apply(stateHits, args);
            } else {
                // replace everything.
                result = action.result;
            }

            let opts = {
                    what: query.what,
                    where: query.where,
                    when: query.when,
                    facets: query.facets
                };

            state = Object.assign({}, state, {result: {...result, query: opts}});
            break;
        case "SEARCH_RESULTS_LOADING":
            let resultsListLoading = (state.resultsListLoading || 0) + (action.isLoading ? 1 : -1);
            state = Object.assign({}, state, {resultsListLoading: resultsListLoading});
            break;
        case "SEARCH_FACET_MOBILE_DISPLAY":
            state = Object.assign({}, state, {mobileFacetDisplay: action.state});
        case "SEARCH_RESULT_LIST_STYLE":
            state = Object.assign({}, state, {resultListStyle: action.style});
            break;
    }

    return state;
};
