import classnames from "classnames";
import React, {Component} from "react"
import {Overlay} from "react-overlays"
import {ListGroup, ListGroupItem} from "react-bootstrap"
import { findDOMNode } from "react-dom"
import {emphasis} from "uiwidgets-react-legacy";

export default class Select extends Component {
    constructor(props) {
        super(props);

        this.delayTimer = false;
        this.state = {loading: false, show: false};
    }

    componentWillUnmount() {
        // cancel any outstanding timers to prevent anything acting on an unmounted component.
        if (this.delayTimer !== false) {
            clearTimeout(this.delayTimer);
            this.delayTimer = false;
        }
    }

    doQuery(q) {
        var lookupFn = this.props.onLookup;

        lookupFn(q)
            .then(function (results) {
                this.setState({loading: false, results: results});
            }.bind(this));

        // show the results list and set the loading state...
        this.setState({loading: true, show: true});
    }

    inputChange() {
        var q = this.refs.input.value;

        this.props.onChange(q);

        // only query if the input value length is >= to the specified minimum query length.
        if (q.length >= this.props.minQueryLength) {
            // here, we defer the sending of the query to allow the user to type more, the primary objective is to
            // stop the backend being needlessly swamped with requests for which the responses will not be used. Lets
            // start by checking if we've already deferred a query...
            if (this.delayTimer !== false) {
                // we have already deferred a query, so lets cancel that and defer again.
                clearTimeout(this.delayTimer);
            }

            // defer the query for a short time later...
            this.delayTimer = setTimeout(() => {
                // clear the timeout from state and send the query.
                this.delayTimer = false;
                this.doQuery(q);
            }, this.props.delayPeriod);

        } else if (this.state.show) {
            // hide the current list.
            this.setState({show: false});
        }
    }

    onKeyDown(ev) {
        var q = this.refs.input.value,
            resultsList = this.refs.resultsList;

        switch(ev.keyCode) {
            case 13: // enter
                if (this.state.show) {
                    // TODO: trigger selection.
                    resultsList.highlightSelect();
                }
            case 38: // up
                if (this.state.show) {
                    ev.preventDefault();

                    resultsList.highlightPrev();
                }
                break;
            case 40: // down
                // if the result list is not visible and we have
                if (!this.state.show) {
                    if (q.length >= this.props.minQueryLength) {
                        this.doQuery(q);
                    }
                } else {
                    ev.preventDefault();

                    resultsList.highlightNext();
                }
                break;
        }
    }

    onSelect(idx, text) {
        this.refs.input.value = text;
        this.setState({show: false});
    }

    render() {
        var results = this.state.results || [],
            inputRef = this.refs.input;

        return (
            <div style={{position: "relative"}}>
                <input type="text"
                       className={classnames("form-control", this.props.className)}
                       defaultValue={this.props.defaultValue}
                       placeholder={this.props.placeholder}
                       onChange={() => {this.inputChange()}}
                       onKeyDown={(ev) => {this.onKeyDown(ev)}}
                       ref="input" />

                <Overlay container={this}
                         onHide={() => {this.setState({show: false})}}
                         placement="bottom"
                         rootClose={true}
                         show={this.state.show}
                         target={(props) => findDOMNode(this.refs.input)}>

                        <SelectResultList results={results}
                                          highlight={inputRef && inputRef.value}
                                          onSelect={this.onSelect.bind(this)}
                                          ref="resultsList" />
                </Overlay>
            </div>
        );
    }
}

Select.defaultProps = {
    defaultValue: "",
    delayPeriod: 150,
    minQueryLength: 3,
    onChange: function() {},
    onLookup: function() { return Promise.resolve([]); }
};

export class SelectResultList extends Component {
    constructor(props) {
        super(props)
        this.state = {highlighted: null}
    }

    highlightSelect() {
        var result = this.result(this.state.highlighted),
            text = result.value;

        this.onSelect(this.state.highlighted, text)
    }

    highlightNext() {
        if (Number.isInteger(this.state.highlighted)) {
            if (this.state.highlighted < this.resultCount-1) {
                this.setState({highlighted: this.state.highlighted + 1});
            }
            return ;
        }

        this.setState({highlighted: 0});
    }

    highlightPrev() {
        if (this.state.highlighted) {
            this.setState({highlighted: this.state.highlighted - 1});
        }
    }

    result(idx) {
        var results = (this.props && this.props.results) || {},
            museums = results.museums || [],
            cityStates = results.cityStates || [];

        if (idx < cityStates.length) {
            return cityStates[idx];
        } else {
            idx-= cityStates.length;
            return museums[idx];
        }

        return undefined;
    }

    get resultCount() {
        var results = (this.props && this.props.results) || {},
            museums = results.museums || [],
            cityStates = results.cityStates || [];

        return museums.length + cityStates.length;
    }

    set resultCount(val) {
        // do nothing. HMR complains bitterly about read-only properties.
    }

    onSelect(index, text, ev) {
        this.props.onSelect && this.props.onSelect(index, text);
    }

    render() {
        var results = this.props.results || {},
            cityStates =  results.cityStates || [],
            museums = results.museums || [],
            q = this.props.highlight || "",
            listIdx = 0;

        if (cityStates.length || museums.length) {
            return (
                <div className="uiw-select-list-wrapper" style={{zIndex: 20000}}>
                    <ListGroup>
                        {(() => {
                            if (cityStates.length) {
                                return (
                                    <ListGroupItem>
                                        <h4>Cities / States / Metro Areas</h4>
                                        <ListGroup>
                                            {cityStates.map((cityState, idx) => {
                                                var classNames = "",
                                                    result;

                                                if (listIdx === this.state.highlighted) {
                                                    classNames += "uiw-selected";
                                                }

                                                result = <ListGroupItem key={idx}
                                                                        onClick={this.onSelect.bind(this, listIdx, cityState.value)}
                                                                        className={classNames}>{emphasis(cityState.label, q)}</ListGroupItem>
                                                listIdx++;

                                                return result;
                                            })}
                                        </ListGroup>
                                    </ListGroupItem>
                                );
                            }
                        }).call(this)}
                        {(() => {
                            if (museums.length) {
                                return (
                                    <ListGroupItem>
                                        <h4>Museums</h4>
                                        <ListGroup>
                                            {museums.map((museum, idx) => {
                                                var classNames = "",
                                                    result;

                                                if (listIdx === this.state.highlighted) {
                                                    classNames += "uiw-selected";
                                                }

                                                result = (<ListGroupItem key={idx}
                                                                         onClick={this.onSelect.bind(this, listIdx, museum.value)}
                                                                         className={classNames}>{emphasis(museum.label, q)}</ListGroupItem>);

                                                listIdx++;

                                                return result;
                                            })}
                                        </ListGroup>
                                    </ListGroupItem>
                                );
                            }
                        })()}
                    </ListGroup>
                </div>
            );
        } else {
            return(
                <div className="uiw-select-list-wrapper" style={{zIndex: 20000}}>
                    <ListGroup><ListGroupItem>No Results</ListGroupItem></ListGroup>
                </div>);
        }
    }
}
