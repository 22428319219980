import React, {Component} from "react";
import {Button, Panel} from "react-bootstrap";
import SearchResultFacetGroup from "./SearchResultFacetGroup";
import {MAX_FACETS} from "./SearchResultFacets";

export default class SearchResultFacet extends Component {
    constructor(props) {
        super(props);

        this.state = {showMore: false};
        this.toggleShowMore = this.toggleShowMore.bind(this);
    }

    toggleShowMore() {
        this.setState({showMore: !this.state.showMore});
    }

    render() {
        const {categoryLabels, facet, facetLabels, onFacetClick, selected} = this.props;
        const labelKey = facet.labelKey || facet.key;
        const facetHeading = facetLabels[labelKey] || labelKey;
        const {showMore} = this.state;

        return (
            <Panel>
                <Panel.Heading>{facetHeading}</Panel.Heading>
                <Panel.Body>
                    <SearchResultFacetGroup categoryLabels={categoryLabels}
                                            facetKey={facet.key}
                                            facets={facet.value}
                                            onFacetClick={onFacetClick}
                                            selected={selected}
                                            showMore={showMore} />

                    {(facet.value.length > MAX_FACETS) && <Button bsStyle="link" onClick={this.toggleShowMore}>Show {showMore ? "less" : "more"}</Button>}
                </Panel.Body>
            </Panel>
        );
    }
}
