import PropTypes from "prop-types";
import React, {Component} from "react";
import { connect } from "react-redux";
import CodeExpired from "./CodeExpired";
import CodeForm from "./CodeForm";
import CodeNotFound from "./CodeNotFound";
import CodeSent from "./CodeSent";
import PasswordChanged from "./PasswordChanged";
import ResetPasswordForm from "./ResetPasswordForm";

/**
 * This component has the responsibility of delegating to the correct
 * ResetPassword component, depending on the current state of ResetPassword.
 */
export class ResetPasswordCode extends Component {
    static propTypes = {
        code: PropTypes.string,
        init: PropTypes.func.isRequired,
        status: PropTypes.string
    }

    componentWillMount() {
        this.props.init();
    }

    render() {
        let {code} = this.props;

        switch(this.props.status) {
            case "code_not_found":
                return <CodeNotFound />
            case "code_expired":
                return <CodeExpired code={code} />
            case "code_sent":
                return <CodeSent />
            case "password_changed":
                return <PasswordChanged />
            default:
                if (!code) {
                    return <CodeForm />
                }

                return <ResetPasswordForm code={code} />
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        init: function() {
            dispatch({
                type: "USER_RESET_PASSWORD_STATE",
                status: undefined
            });
        }
    }
};

const mapStateToProps = (state, props) => {
    var resetPassword = state.auth.resetPassword;

    return {
        code: props.params.code,
        status: resetPassword.status
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCode);
