import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tooltip } from "uiwidgets-react-legacy";
import { OutboundLink } from "../../react-redux-ga";

export class ExternalLink extends Component {
    static propTypes = {
        _id: PropTypes.string.isRequired,
        imageId: PropTypes.string,
        label: PropTypes.string.isRequired,
        notes: PropTypes.string,
        url: PropTypes.string.isRequired
    };

    render() {
        const { _id: id, imageId, label, url } = this.props;

        return (
            <div className="ag-external-link">
                <Tooltip tooltipText={label}>
                    <OutboundLink action="ExternalLink"
                                  category="OutboundLink"
                                  className="ag-external-link-title"
                                  href={url}
                                  label={`${id} - ${label}`}
                                  target="_blank">
                        {imageId ? <img alt={label} src={`/api/media/${imageId}`} /> : <span>{label}</span>}
                    </OutboundLink>
                </Tooltip>
            </div>
        );
    }
}
