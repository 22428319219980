import PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {installBodyClass} from "./browser";

export default class ScrollToTop extends PureComponent {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        title: PropTypes.string
    };

    static defaultProps = {
        title: "Go to top"
    };

    componentDidMount() {
        // componentDidMount only runs in the browser, so only install the bodyClass handler if we're in the browser.
        installBodyClass();
    }

    onClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        window.scrollTo(0, 0);
    }

    render() {
        const {title} = this.props;
        return (
            <a className="uiw-go2top-link" href="#" onClick={this.onClick} title={title}>
                <i className="fa fa-chevron-up" aria-hidden="true"></i>
            </a>
        );
    }
};
