import PropTypes from "prop-types";
import React from "react";
import {BookmarkToggle} from "../bookmark"

const MuseumBookmarkToggle = (props) => {
    const {museum} = props;
    if (!museum || !museum._id) {
        return null;
    }

    return <BookmarkToggle entityId={museum._id} entityType="Museum" title={museum.name} />
};

MuseumBookmarkToggle.propTypes = {
    museum: PropTypes.object
};

export default MuseumBookmarkToggle
