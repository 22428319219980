import React, {PureComponent} from "react";
import Helmet from "react-helmet";
import {Link} from "react-router";
import {connect} from "react-redux";
import {createSelector} from "reselect";
import sortBy from "sort-by";
import {Tooltip} from "uiwidgets-react-legacy";
import us from "us";
import championImage from "./ArtGeek_ChampionBadge_sm.png";

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const endOfDay = (date) => {
    const now = date ? new Date(date) : new Date();
    now.setHours(23, 59, 59, 0);
    return now;
};

const CharacterIndex = (props) => {
    const {statesByLetter} = props;
    const letters = ALPHABET.map(letter => ({
        enabled: !!statesByLetter[letter],
        letter: letter
    }));

    return (
        <ul className="hn-browse-atoz ag-atoz-main h3 list-unstyled text-center">
            {letters.map((alpha) => {
                return (
                    <li key={alpha.letter}>
                        {alpha.enabled
                            ? <a href={`#alpha${alpha.letter}`}>{alpha.letter}</a>
                            : <span>{alpha.letter}</span>
                        }
                    </li>
                )
            })}
        </ul>
    );
};

const StatesIndex = (props) => {
    const {states} = props;
    return (
        <ul className="hn-browse-atoz h3 list-unstyled text-center">
            {states.map(state => {
                return (
                    <li key={state.code}>
                        <a href={`#state${state.code}`}>{state.label}</a>
                    </li>
                );
            })}
        </ul>
    )
};

const Associations = (props) => {
    if (!props.keys || !props.keys.length) {
        return null;
    }

    return (
        <span className="label hn-label-associate">
            <i className="fa fa-users fa-fw" aria-hidden="true"></i>
            {props.keys.map((association) => {
                return association && association.name;
            })}
        </span>
    )
}

const Champion = (props) => {
    return (
        <Tooltip tooltipText="ArtGeek Champion!">
            <img className="ag-champion" alt="ArtGeek Champion" src={championImage} />
        </Tooltip>
    )
};

class StateList extends PureComponent {
    render() {
        const {champions, statesByLetter} = this.props;

        return (
            <ul className="hn-museum-full-list list-unstyled">
                {Object.keys(statesByLetter).map(letter => {
                    const states = statesByLetter[letter];
                    const id = `alpha${letter}`;

                    return (
                        <li key={letter}>
                            <h2 id={id} className="ag-atoz-alpha-headings">{letter}</h2>
                            <StatesIndex states={states} />
                            <ul className="list-unstyled">
                                {states.map(state => {
                                    const id = `state${state.code}`;
                                    return (
                                        <li className="ag-museum-list-state-section" id={id} key={state.code}>
                                            <span className="ag-museum-list-state h2">{state.label} ({state.code})</span>
                                            <ul className="hn-atoz-city list-unstyled">
                                                {state.cities.map((city, idx) => {
                                                    return (
                                                        <li key={idx}>
                                                            <span className="h4">{city.label}</span>
                                                            <ul>
                                                                {city.museums.map((museum) => {
                                                                    const href = museum.path || `/museums/${museum._id}`;
                                                                    return (
                                                                        <li key={museum._id}>
                                                                            <Link to={href}>{museum.name}</Link>
                                                                            {champions[museum._id] ? <Champion /> : null}
                                                                            <Associations keys={museum.associations} />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        );
    }
};

export function MuseumList(props) {
    const {champions, statesByLetter} = props;
    return (
        <div className="ag-view-heading hn-view-static hn-view-coverage">
            <Helmet title="Museum List by State - ArtGeek" />

            <div className="jumbotron row">
                <div className="container">
                    <div className="hn-hero-titles row">
                        <h1>Museum List by State</h1>
                    </div>
                </div>
            </div>

            <div className="container">
                <CharacterIndex statesByLetter={statesByLetter} />
                <StateList champions={champions} statesByLetter={statesByLetter} />
            </div>
        </div>
    );
};

MuseumList.defaultProps = {
    champions: {},
    statesByLetter: []
};

const coverageSelector = state => state.museum.coverage;
const associationsSelector = state => state.assoc.hash;
const endOfDaySelector = state => endOfDay().toISOString();

const museumListSelector = createSelector([coverageSelector, associationsSelector, endOfDaySelector], (coverage, hash, now) => {
    const champions = {};

    const statesByLetter = coverage.reduce((result, museumState) => {
        const cities = museumState.cities.map((city) => {
            const museums = city.museums.map((museum) => {
                const associations = (museum.associations || []).map((associationId) => {
                    return hash[associationId];
                });

                // if the museum has a champion expiry date, and it has not passed, then it is a champion.
                if (museum.championExpiryDate) {
                    const expiryDate = new Date(museum.championExpiryDate);
                    if (expiryDate.toISOString() > now) {
                        champions[museum._id] = true;
                    }
                }

                return {...museum, associations};
            });
            return {...city, museums};
        });

        const letter = museumState.label[0];
        const states = result[letter] || (result[letter] = []);
        const stateMeta = us.states[museumState.label];
        states.push({
            code: museumState.label,
            label: stateMeta && stateMeta.name,
            cities
        });

        states.sort(sortBy("label"));

        return result;
    }, {});

    return {champions, statesByLetter};
});

const mapStateToProps = (state) => {
    return museumListSelector(state);
};

export default connect(mapStateToProps)(MuseumList);
