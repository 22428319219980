import React from "react";
import { connect } from "react-redux";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import { Link } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import UserMenu from "./UserMenu";

export const Header = ({blogUri}) => (
    <Navbar>
        <Navbar.Header>
            <Navbar.Brand><Link to="/"><span className="sr-only">ArtGeek</span></Link></Navbar.Brand>
            <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
            <Nav pullRight>
                <NavItem eventKey={2} href={blogUri}>
                    Art Travel Blog
                </NavItem>
                <LinkContainer to="/museums">
                    <NavItem eventKey={1}>
                        Museum List by State
                    </NavItem>
                </LinkContainer>
                <UserMenu />
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

const mapStateToProps = (state) => {
    return {
        blogUri: state.home.blogUri
    };
};

export default connect(mapStateToProps)(Header);
