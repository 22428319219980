import PropTypes from "prop-types";
import React from "react";
import hoistStatics from "hoist-non-react-statics";
import Unauthenticated from "./Unauthenticated";

/**
 * Buld a function for checking a single permission.
 *
 * @param permission {String} the permission code.
 * @return {Function} the permission checker
 */
function buildSingleCheck(permission) {
    return function(userPermissions) {
        return userPermissions.indexOf(permission) !== -1;
    };
}

/**
 * Build a function for checking permissions.
 *
 * @param permissions {String} the permission spec.
 * @return {Function} a function for checking permissions
 */
function buildPermissionCheck(permissions) {
    var fn = function() { return true; };

    if (typeof permissions === "string") {
        // create a function that checks for the single permission.
        fn = buildSingleCheck(permissions);
    }

    return fn;
}

export default function withPermission(WrappedComponent, permissions) {

    var checkPermissions = buildPermissionCheck(permissions);

    function WithPermission(props, context) {
        var {auth} = context,
            {components = {}, provider} = auth;

        if (provider.isAuthenticated()) {
            let payload = provider.payload();
            let userPermissions = payload.user.permissions || [];

            if (!checkPermissions(userPermissions)) {
                return <div>Not permitted</div>
            }

            return <WrappedComponent {...props} />
        } else {
            // render Unauthenticated component, first we look to the authentication context to see if an
            // Unauthenticated view has been configured there, otherwise fallback to the default.
            let UnauthenticatedView = components.unauth || Unauthenticated;
            return <UnauthenticatedView />
        }
    }

    WithPermission.contextTypes = {auth: PropTypes.object.isRequired};

    let displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";
    WithPermission.displayName = `withPermission(${displayName})`;
    WithPermission.WrappedComponent = WrappedComponent;

    // use hoistStatics so that any statics on the component are available on the wrapper.
    return hoistStatics(WithPermission, WrappedComponent);
}
