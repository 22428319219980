import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { findDOMNode } from "react-dom";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import ProfileSection from "./ProfileSection";
import { authFetch, loadProfile, saveProfile, changePassword} from "../AuthActions";
import { ValidationHint, required, validationState } from "../../../uiw-widgets/LegacyFormValidation";

export class Profile extends Component {
    constructor() {
        super();

        this.state = {activeSection: null};

        this.handleLookup = this.handleLookup.bind(this);
        this.saveEmail = this.saveEmail.bind(this);
        this.savePassword = this.savePassword.bind(this);
        this.saveUsername = this.saveUsername.bind(this);
        this.setActiveSection = this.setActiveSection.bind(this);
    }

    static propTypes = {
        authToken: PropTypes.string,
        errors: PropTypes.array,
        fieldErrors: PropTypes.object,
        lookupUsername: PropTypes.func.isRequired,
        profile: PropTypes.object,
        saveProfile: PropTypes.func.isRequired
    }

    static defaultProps = {
        errors: [],
        fieldErrors: {},
        profile: {}
    }

    handleLookup(ev) {
        let username = findDOMNode(this.refs.username).value;
        if (this.props.profile.username !== username) {
            this.props.lookupUsername(username);
        }
    }

    saveEmail(ev) {
        ev.preventDefault();

        let email = findDOMNode(this.refs.email).value;

        this.props.saveProfile({email})
            .then(() => {
                this.setActiveSection(null);
            });
    }

    savePassword(ev) {
        ev.preventDefault();

        let oldPassword = findDOMNode(this.refs.oldPassword).value;
        let newPassword = findDOMNode(this.refs.newPassword).value;
        let confirmPassword = findDOMNode(this.refs.confirmPassword).value;

        this.props.savePassword({oldPassword, newPassword, confirmPassword})
            .then((response) => {
                // if successful, we can close the `Change Password` section.
                if (response.status === "ok") {
                    this.setActiveSection(null);
                }
            });
    }

    setActiveSection(id) {
        this.setState({activeSection: id});
    }

    saveUsername(ev) {
        ev.preventDefault();

        let username = findDOMNode(this.refs.username).value;

        this.props.saveProfile({username})
            .then(() => {
                this.setActiveSection(null);
            });
    }

    render() {
        const {profile} = this.props;
        const {activeSection} = this.state;

        return (
            <div className="ag-view-heading hn-view-profile">
                <Helmet title="Profile" />

                <div className="jumbotron row">
                    <div className="container">
                        <div className="hn-view-profile-image-container">
                            <div className="hn-hero-titles">
                                <h1>Profile</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <ProfileSection id="username" heading="Username" helpText="Change your username." active={activeSection === "username"} setActive={this.setActiveSection}>
                    <form className="form-horizontal" onSubmit={this.saveUsername}>
                        <FormGroup controlId="username" validationState={validationState(this.props, "username")}>
                            <ControlLabel bsClass="col-sm-2 control-label">Username</ControlLabel>
                            <div className="col-sm-8">
                                <FormControl type="text"
                                             ref="username"
                                             autoComplete="off"
                                             defaultValue={profile.username}
                                             onChange={this.handleLookup} />

                                <ValidationHint name="username" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="col-sm-offset-2 col-sm-10">
                                <button type="button" className="btn btn-default" onClick={() => {this.setActiveSection(null)}}>Cancel</button>
                                &nbsp;
                                <button type="submit" className="btn btn-primary" disabled={this.props.fieldErrors["username"]}>Save</button>
                            </div>
                        </FormGroup>
                    </form>
                </ProfileSection>

                <ProfileSection id="email" heading="Email" helpText="Change your email address." active={activeSection === "email"} setActive={this.setActiveSection}>
                    <form className="form-horizontal" onSubmit={this.saveEmail}>
                        <FormGroup controlId="email"  validationState={validationState(this.props, "email")}>
                            <ControlLabel bsClass="col-sm-2 control-label">Email</ControlLabel>
                            <div className="col-sm-8">
                                <FormControl type="text"
                                             defaultValue={profile.email}
                                             ref="email" />

                                <ValidationHint name="email" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="col-sm-offset-2 col-sm-10">
                                <button type="button" className="btn btn-default" onClick={() => {this.setActiveSection(null)}}>Cancel</button>
                                &nbsp;
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </FormGroup>
                    </form>
                </ProfileSection>

                <ProfileSection id="password" heading="Password" helpText="Change your password." active={activeSection === "password"} setActive={this.setActiveSection}>
                    <form className="form-horizontal" onSubmit={this.savePassword}>
                        <FormGroup controlId="oldPassword" validationState={validationState(this.props, "oldPassword")}>
                            <ControlLabel bsClass="col-sm-2 control-label">Old Password</ControlLabel>
                            <div className="col-sm-8">
                                <FormControl type="password"
                                             ref="oldPassword" />

                                <ValidationHint name="oldPassword" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <FormGroup controlId="newPassword" validationState={validationState(this.props, "newPassword")}>
                            <ControlLabel bsClass="col-sm-2 control-label">New Password</ControlLabel>
                            <div className="col-sm-8">
                                <FormControl type="password"
                                             ref="newPassword" />

                                <ValidationHint name="newPassword" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <FormGroup controlId="confirmPassword" validationState={validationState(this.props, "confirmPassword")}>
                            <ControlLabel bsClass="col-sm-2 control-label">Confirm Password</ControlLabel>
                            <div className="col-sm-8">
                                <FormControl type="password"
                                             ref="confirmPassword" />

                                <ValidationHint name="confirmPassword" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="col-sm-offset-2 col-sm-10">
                                <button type="button" className="btn btn-default" onClick={() => {this.setActiveSection(null)}}>Cancel</button>
                                &nbsp;
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </FormGroup>
                    </form>
                </ProfileSection>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        lookupUsername: (q) => {
            dispatch(authFetch(`/api/user/username/?q=${encodeURIComponent(q)}`))
                .then((response) => {
                    return response.json();
                })
                .then((body) => {
                    var fieldErrors = {};

                    if (body.taken) {
                        fieldErrors.username = {state: "error", message: "username already taken"};
                    }

                    dispatch({
                        type: "USER_PROFILE_CHANGE_PASSWORD",
                        errors: [],
                        fieldErrors
                    });
                });
        },

        savePassword: (data) => {
            var fieldErrors = {};
            required(fieldErrors, data, "oldPassword");
            required(fieldErrors, data, "newPassword");
            required(fieldErrors, data, "confirmPassword");

            dispatch({
                type: "USER_PROFILE_CHANGE_PASSWORD",
                errors: [],
                fieldErrors
            });

            // if there are any field errors, resolve the promise BEFORE dispatching the `changePassword` action to
            // return control to the UI.
            if (Object.keys(fieldErrors).length > 0) {
                return Promise.resolve({status: "err", fieldErrors});
            }

            return dispatch(changePassword(data));
        },

        loadProfile: () => {
            return dispatch(loadProfile());
        },

        saveProfile: (profile) => {
            return dispatch(saveProfile(profile));
        }
    };
};

const mapStateToProps = (state) => {
    return {
        authToken: state.auth.token,
        profile: state.profile.model || {},
        fieldErrors: state.profile.fieldErrors || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
