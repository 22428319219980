import React from "react";

export default function CodeSent(props) {
    return (
        <div>
            <h1>Reset Password Sent</h1>

            <p>Please check your email for a reset password code/link.</p>
        </div>
    )
}
