import PropTypes from "prop-types";
import React from "react";
import AmazonProductWidget from "./AmazonProductWidget"

const AmazonProductsList = (props) => {
    return (
        <ul className="list-inline">
        {props.products.map((product) => {
            return (
                <li key={product.identifier}>
                    <AmazonProductWidget affiliateId={props.affiliateId} asin={product.identifier} />
                </li>
            );
        })}
        </ul>
    );
};

AmazonProductsList.propTypes = {
    affiliateId: PropTypes.string.isRequired,
    products: PropTypes.array.isRequired
};

export default AmazonProductsList;
