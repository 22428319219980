import fetch from "isomorphic-fetch";
import {extractPayload, hasExpired} from "./JwtUtils";

/**
 * Check to see if the given URI has a different origin to that of the current window.location. This is used to
 * determine a value that can be used for the "credentials" property in the fetch API.
 *
 * @param uri {String} the target URI.
 * @returns {String} the value for the "credentials" property.
 */
export function resolveCredentials(uri) {
    let uriMatch = uri.match(/([^:]*:\/\/[^\/]*)\//),
        origin = uriMatch[1];

    // TODO: will this work on the server?
    return window.location.origin === origin ? "same-origin": "include";
}

export default function authMiddleware(authProvider, uriFn) {
    return ({dispatch}) => {
        return (next) => {
            return (action) => {
                if (action && action.type) {
                    switch (action.type) {
                        case "@@auth/CALL_LOGIN":
                            authProvider.login(action.token);
                            return;
                        case "@@auth/CALL_LOGOUT":
                            authProvider.logout();
                            return;
                        case "@@auth/FETCH":
                            const opts = action.opts || {};
                            const token = authProvider.token();
                            if (token) {
                                const headers = opts.headers || (opts.headers = {});
                                headers["Authorization"] = "Bearer " + token;
                            }

                            const uri = uriFn ? uriFn(action.uri) : action.uri;

                            return fetch(uri, opts);
                    }
                }

                return next(action);
            }
        }
    }
}
