import classNames from "classnames";
import React, {Component} from "react";
import {Badge, ListGroupItem} from "react-bootstrap";
import Checkbox from "./Checkbox";
import SearchResultFacetGroup from "./SearchResultFacetGroup";

export default class SearchResultFacetValue extends Component {
    constructor(props) {
        super(props);

        this.onFacetClick = this.onFacetClick.bind(this);
    }

    onFacetClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        const {facetKey, facetItem, onFacetClick} = this.props;
        onFacetClick && onFacetClick(facetKey, facetItem.key, ev);
    }

    render() {
        const {categoryLabels, facetKey, facetItem, onFacetClick, selected, showMore} = this.props;
        const isLabel = typeof facetItem.value === "undefined";
        const labelText = categoryLabels[facetItem.key] || facetItem.key;

        // it's important that isChecked is specifically a BOOLEAN type or you'll get warnings from React because
        // switching between controller and uncontrolled component.
        const isChecked = !!(selected[facetKey] && selected[facetKey][facetItem.key]);
        const isIndeterminate = !isChecked && facetItem.hasSelectedChildren;

        let nodeBody;

        if (isLabel) {
            nodeBody = <h4>{labelText}</h4>
        } else {
            nodeBody = <div className={classNames('hn-facet-wrapper', {'hn-facet-unavailable': facetItem.value === 0})}>
                <Checkbox className="uiw-checkbox hn-facet" inline
                          checked={isChecked}
                          indeterminate={isIndeterminate}
                          onChange={this.onFacetClick}>{labelText}</Checkbox>
                <Badge>{facetItem.value}</Badge>
            </div>
        }

        const listGroupItemClasses = classNames({"hn-facet-clear": !isChecked && !isIndeterminate});
        return (
            <ListGroupItem className={listGroupItemClasses}>
                {nodeBody}
                {facetItem.subs && <SearchResultFacetGroup categoryLabels={categoryLabels}
                                                           facetKey={facetKey}
                                                           facets={facetItem.subs}
                                                           onFacetClick={onFacetClick}
                                                           selected={selected}
                                                           showMore={showMore} />}
            </ListGroupItem>
        )
    }
}
