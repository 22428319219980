import {authFetch} from "../auth";

export const fetchAssociations = () => {
    return (dispatch) => {
        return dispatch(authFetch("/api/assoc?limit=0"))
            .then((response) => {
                return response.json();
            })
            .then((body) => {
                const assocs = body.results.map((assoc) => {
                    if (assoc.imageId) {
                        assoc.imageSrc = `/api/media/${assoc.imageId}`;
                    }
                    return assoc;
                });
                dispatch(associations(assocs));
                return assocs;
            });
    };
};

export const associations = (associations) => {
    return {
        type: "ASSOCIATIONS",
        associations
    }
};
