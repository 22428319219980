import Exhibition from "./Exhibition";
import {getExhibition} from "./ExhibitionActions";
import {getCategories} from "../categories/CategoryActions";

export default (store) => {
    const {dispatch} = store;
    return [{
        childRoutes: [{
            path: ":exhibitionId",
            component: Exhibition,
            onEnter: (nextState, redirect, callback) => {
                const {exhibitionId} = nextState.params;
                // TODO: fetch bookmarks if authenticated?
                dispatch(getExhibition(exhibitionId))
                    .then(() => {
                        callback();
                    })
                    .catch((err) => {
                        console.error(err);
                        callback();
                    });
            }
        }, {
            path: ":exhibitionId/:eventId",
            component: Exhibition,
            onEnter: (nextState, redirect, callback) => {
                const {exhibitionId} = nextState.params;
                // TODO: fetch bookmarks if authenticated?
                const promises = [dispatch(getCategories()), dispatch(getExhibition(exhibitionId))];

                Promise.all(promises)
                    .then(() => {
                        callback();
                    })
                    .catch((err) => {
                        console.error(err);
                        callback();
                    });
            }
        }]
    }];
};
