import {extractPayload} from "./JwtUtils";

const initialState = {
    register: {},
    resetPassword: {},
    verify: {}
};

/**
 * Authentication functionality.
 */
export default function AuthReducer(state = initialState, action) {
    switch(action.type) {
        case "@@auth/LOGIN":
            state = {...state, token: action.token, user: action.user};
            break;
        case "@@auth/LOGOUT":
            state = {...state};
            delete state.user;
            delete state.token;
            break;
        case "AUTH_ERROR":
            state = Object.assign({}, state, {error: action.error});
            break;
        case "AUTH_FAILURE":
            state = Object.assign({}, state, {error: "invalid uid/pwd"});
            break;
        case "REGISTER_FIELD_ERRORS":
            state = Object.assign({}, state, {
                fieldErrors: action.fieldErrors,
                errors: action.errors
            });
            break;
        case "AUTH_VERIFY_ERRORS":
            state = Object.assign({}, state, {
                verify: {
                    errors: action.errors,
                    fieldErrors: action.fieldErrors
                }
            });
            break;
        case "AUTH_VERIFY_STATE":
            state = Object.assign({}, state, {
                verifyState: action.state,
                verify: {
                    errors: action.errors,
                    fieldErrors: action.fieldErrors
                }
            });
            break;
        case "REGISTER_SUCCESS":
            state = Object.assign({}, state, {
                registeredState: "registered"
            });
            break;
        case "REGISTER_USERNAME_LOOKUP":
            state = Object.assign({}, state, {
                usernameTaken: action.taken
            });
            break;
        case "REGISTER_ERROR":
            state = Object.assign({}, state, {
                errors: action.errors
            });
            break;
        case "USER_RESET_PASSWORD_ERRORS":
            state = Object.assign({}, state, {
                resetPassword: {
                    state: action.status,
                    errors: action.errors,
                    fieldErrors: action.fieldErrors
                }
            });
            break;
        case "USER_RESET_PASSWORD_STATE":
            state = Object.assign({}, state, {
                resetPassword: {
                    status: action.status || state.resetPassword.status,
                    errors: action.errors || [],
                    fieldErrors: action.fieldErrors || {}
                }
            });
            break;

    }

    return state;
}
