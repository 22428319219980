import cookie from "browser-cookies"

const TOKEN_KEY = "__JWT";

export default {
    removeToken: function() {
        if (!cookie.get(TOKEN_KEY)) {
            return false;
        }
        cookie.erase(TOKEN_KEY);
        return true;
    },

    token: function(value) {
        if (!value) {
            return cookie.get(TOKEN_KEY);
        }

        cookie.set(TOKEN_KEY, value)
        return value;
    }
}
