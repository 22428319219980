import { Cache } from "memory-cache";
import { flatten } from "./CategoryUtils"
import { authFetch } from "../auth";

const cache = new Cache();

export function getCategories() {
    return (dispatch) => {
        const cachedCategories = cache.get("categories");
        const promise = cachedCategories
                ? Promise.resolve(cachedCategories)
                : dispatch(authFetch("/api/category"))
                    .then((response) => {
                        return response.json()
                            .then((data) => {
                                return flatten({}, data);
                            })
                            .then(categories => {
                                // cache the category values for a day.
                                cache.put("categories", categories, 86400000);
                                return categories;
                            });
                    });

        return promise
            .then((categories) => {
                return dispatch({
                        type: "CATEGORIES",
                        categories: categories
                    });
            })
            .catch((err) => {
                return dispatch({
                        type: "ERROR",
                        message: err.message,
                        stack: err.stack
                    });
            });
    }
}
