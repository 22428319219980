import Museum from "./Museum";
import Museums from "./Museums";
import {coverage, getMuseum} from "./MuseumActions";
import {fetchAssociations} from "../assoc";

export const createRoutes = (store) => {
    return [{
        indexRoute: {
            component: Museums,
            onEnter: (nextState, replace, callback) => {
                Promise.all([store.dispatch(coverage()), store.dispatch(fetchAssociations())])
                    .then(() => {callback()});
            }
        },
        childRoutes: [{
            path: ":museumId",
            component: Museum,
            onEnter: (nextState, replace, callback) => {
                const {museumId} = nextState.params;
                // TODO: fetch bookmarks if authenticated?                
                Promise.all([store.dispatch(getMuseum(museumId)), store.dispatch(fetchAssociations())])
                    .then(() => {callback()});
            }
        }]
    }];
};
