import { authFetch } from "../auth";
import { normalise as normaliseCategories } from "../categories/CategoryUtils";
import { fetchedMuseums } from "../museum/MuseumActions";

/**
 * Get a set of exhibition records from the array of exhibition ids passed in.
 *
 * @param exhibitionIds {Array} an array of exhibition ids.
 * @return {Promise} a promise for an array of exhibition records.
 */
export function getExhibitions(exhibitionIds) {
    return (dispatch) => {
        // get a set of UNIQUE exhibition Ids for the hash.
        let uniqueIds = Object.keys(exhibitionIds.reduce((ids, id) => {
                id && (ids[id] = true)
                return ids;
            }, {}));

        const queryString = uniqueIds.map(id => `_id=${id}`).join("&");

        return dispatch(authFetch(`/api/exhibition?limit=0&${queryString}`))
            .then((response) => {
                return response.json();
            })
            .then((exhibitions) => {
                // normalise the exhibitions.
                return exhibitions.results.map((exhibition) => {
                    // bind an "heroImage" property onto each exhibition, this will be the URL for the hero
                    // image for the exhibition.
                    if (exhibition.images && exhibition.images[0]) {
                        exhibition.heroImage = `/api/media/${exhibition.images[0]}`;
                    }

                    exhibition.categories = normaliseCategories(exhibition);

                    return exhibition;
                });
            });
    };
}

/**
 * Get a set of museum records as used by the array of exhibitions passed in.
 *
 * @param exhibitions {Array} an array of exhibition records.
 * @return {Promise} a promise for the hash of museum records (keyed by id).
 */
export function museumsForExhibitions(exhibitions) {
    return (dispatch, getState) => {
        const museumIds = exhibitions.reduce((result, exhibition) => {
                const events = exhibition.events || [];
                for (const event of events) {
                    const museumId = event && event.museumId;
                    museumId && result.push(museumId);
                }
                return result;
            }, []);

        // if there are no museumIds, we can abort early.
        if (museumIds.length === 0) {
            return Promise.resolve([]);
        }

        // assemble the query string and make the request.
        const queryString = museumIds.map((id) => {
                return `_id=${id}`;
            }).join("&");

        return dispatch(authFetch(`/api/museum?limit=0&${queryString}`))
            .then((response) => {
                return response.json();
            })
            .then(json => {
                dispatch(fetchedMuseums(json.results));

                const state = getState();
                
                // return the now normalised museums in a hash...
                return json.results.reduce((result, museum) => {
                    result[museum._id] = state.museum.byId[museum._id];
                    return result;
                }, {});
            });
    };
}

export function getExhibition(id) {
    return (dispatch) => {
        return dispatch(authFetch(`/api/exhibition/${id}`))
            .then((response) => {
                return response.json()
                    .then((exhibition) => {
                        // bind an "heroImage" property onto each exhibition, this will be the URL for the hero image for the
                        // exhibition.
                        if (exhibition.images && exhibition.images[0]) {
                            exhibition.heroImage = `/api/media/${exhibition.images[0]}`;
                        }

                        exhibition.categories = normaliseCategories(exhibition);

                        return exhibition;
                    })
                    .then((exhibition) => {
                        return dispatch(museumsForExhibitions([exhibition]))
                            .then((museums) => {
                                return {
                                    exhibition: exhibition,
                                    museums: museums
                                };
                            });
                    })
                   .then((data) => {
                        return dispatch({
                                type: "GET_EXHIBITION",
                                data: data
                            });
                    });
            })
            .catch((err) => {
                return dispatch({
                        type: "ERROR",
                        message: err.message,
                        stack: err.stack
                    });
            });
    };
}
