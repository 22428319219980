import React, {Component} from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { verify } from "../AuthActions";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import CodeExpired from "./CodeExpired";
import CodeSent from "./CodeSent";
import NotFound from "./NotFound";
import Success from "./Success";
import { ValidationHint, required, validationState } from "../../../uiw-widgets/LegacyFormValidation";

export class Verify extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(ev) {
        ev.preventDefault();

        let verifyIdEl = findDOMNode(this.refs.verifyId);
        this.props.submit && this.props.submit(verifyIdEl.value);
    }

    _renderAlert() {
        var {errors = []} = this.props;
        if (errors.length) {
            return errors.map((error, idx) => {
                return <Alert key={idx} bsStyle="danger">{error.message}</Alert>
            });
        }
    }

    renderInput() {
        return (
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <FormGroup controlId="verifyId" validationState={validationState(this.props, "verifyId")}>
                    <ControlLabel bsClass="col-sm-2 control-label">Verification Key</ControlLabel>
                    <div className="col-sm-8">
                        <FormControl
                            type="text"
                            ref="verifyId"
                            placeholder="Verification Key"
                        />

                        <ValidationHint name="verifyId" fieldErrors={this.props.fieldErrors} />
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className="col-sm-offset-2 col-sm-10">
                        <button type="submit" className="btn btn-primary">Verify</button>
                    </div>
                </FormGroup>
            </form>
        );
    }

    render() {
        var {verifyState, verifyId} = this.props;

        switch (verifyState) {
            case "not_found":
                return <NotFound />
            case "code_expired":
                return <CodeExpired verifyId={verifyId} />
            case "code_sent":
                return <CodeSent />
            case "success":
                return <Success />
        }

        return (
            <div>
                <h1>Verifying Code</h1>

                {this._renderAlert()}

                {this.props.verifyId ? "" : this.renderInput()}
            </div>
        )
    }
}

Verify.defaultProps = {
    errors: [],
    fieldErrors: {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (verifyId) => {
            var fieldErrors = {},
                data = {verifyId};

            required(fieldErrors, data, "verifyId");

            dispatch({
                type: "AUTH_VERIFY_ERRORS",
                errors: [],
                fieldErrors
            });

            if (Object.keys(fieldErrors).length > 0) {
                return
            }

            verifyId = verifyId.trim();

            // dispatch a push action to shove the verifyId onto the URL. this will make the verifyId value available
            // as a property to this Component, which in turn, will be passed down, as necessary, to the NotFound
            // component.
            dispatch(push("/verify/" + verifyId));

            // attempt the verification.
            dispatch(verify(verifyId));
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        errors: state.auth.verify.errors,
        fieldErrors: state.auth.verify.fieldErrors,
        verifyId: props.params.verifyId,
        verifyState: state.auth.verifyState
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
