import PropTypes from "prop-types";
import React, { Component } from "react";
import { ExternalLink } from "./ExternalLink";

export class ExternalLinks extends Component {
    static propTypes = {
        links: PropTypes.array.isRequired
    };

    render() {
        const { links } = this.props;

        return (
            <div className="hn-featured-products">
                {links.map((link, idx) => <ExternalLink key={idx} {...link} />)}
            </div>
        );
    }
}
