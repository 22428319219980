import { authFetch } from "../auth";

export function pageNotFound() {
    return {
        type: "PAGE_NOT_FOUND"
    }
}

export function byId(id) {
    return (dispatch) => {
        return dispatch(authFetch(`/api/page/${encodeURIComponent(id)}`))
            .then((response) => {
                if (response.status === 404) {
                    // no page record.
                    return dispatch(pageNotFound());
                }

                return response.json()
                    .then((json) => {
                        var version = json.versions.find((version) => {
                                return version._id == json.currentVersion;
                            }) || {};

                        return dispatch({
                                type: "PAGE",
                                title: version.title,
                                content: version.content
                            });
                    });
            });
    };
}
