import PropTypes from "prop-types";
import React, {Component} from "react";
import { findDOMNode } from "react-dom";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { resetPasswordCode } from "../AuthActions";
import { ValidationHint, required, validationState } from "../../../uiw-widgets/LegacyFormValidation";

export class CodeNotFound extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        errors: [],
        fieldErrors: {}
    }

    static propTypes = {
        errors: PropTypes.array,
        fieldErrors: PropTypes.object,
        submit: PropTypes.func.isRequired
    }

    handleSubmit(ev) {
        ev.preventDefault();

        let emailEl = findDOMNode(this.refs.email);
        this.props.submit(emailEl.value);
    }

    _renderAlert() {
        var {errors = []} = this.props;
        if (errors.length) {
            return errors.map((error, idx) => {
                return <Alert key={idx} bsStyle="danger">{error.message}</Alert>
            });
        }
    }

    render() {
        return (
            <div>
                <h1>Reset Password Code Not Found</h1>

                <p>We are unable to reset a user account password with this code. Enter your email address and submit the form for a new code.</p>

                {this._renderAlert()}

                <form className="form-horizontal" onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email"  validationState={validationState(this.props, "email")}>
                        <ControlLabel bsClass="col-sm-2 control-label">Email</ControlLabel>
                        <div className="col-sm-8">
                            <FormControl
                                type="text"
                                ref="email"
                                placeholder="Email"
                            />

                            <ValidationHint name="email" fieldErrors={this.props.fieldErrors} />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="col-sm-offset-2 col-sm-10">
                            <button type="submit" className="btn btn-primary">Request Code</button>
                        </div>
                    </FormGroup>
                </form>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (email) => {
            var fieldErrors = {},
                data = {email};

            required(fieldErrors, data, "email");

            dispatch({
                type: "AUTH_RESET_PASSWORD_STATE",
                errors: [],
                fieldErrors
            });

            if (Object.keys(fieldErrors).length > 0) {
                return
            }

            dispatch(resetPasswordCode({email: email.trim()}));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        errors: state.auth.verify.errors,
        fieldErrors: state.auth.verify.fieldErrors,
        verifyState: state.auth.verifyState
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeNotFound);
