import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { lookupUsername, register } from "./AuthActions";
import Checkbox from "../search/Checkbox";
import { debounce } from "uiwidgets-common";
import Password from "../../uiw-widgets/LegacyPassword";
import { ValidationHint, required, validationState } from "../../uiw-widgets/LegacyFormValidation";

class Register extends Component {
    constructor() {
        super()
        this.state = {newsletter: false, showPassword: false};

        // a debounced function to do the username lookup.
        this.debouncedLookup = debounce((value) => {
            this.props.lookup && this.props.lookup(value);
        });

        this.handleLookup = this.handleLookup.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onNewsletterChange = this.onNewsletterChange.bind(this);
        this.onShowPasswordChange = this.onShowPasswordChange.bind(this);
    }

    handleLookup(ev) {
        ev.preventDefault();

        let usernameEl = findDOMNode(this.refs.username);
        this.debouncedLookup(usernameEl.value);
    }

    handleSubmit(ev) {
        ev.preventDefault();

        let usernameEl = findDOMNode(this.refs.username);
        let passwordEl = findDOMNode(this.refs.password);
        let emailEl = findDOMNode(this.refs.email);

        let data = {
            user: {
                username: usernameEl.value,
                password: passwordEl.value,
                email: emailEl.value,
            },
            // the wording means we must invert the flag for transit.
            newsletter: !this.state.newsletter
        };

        this.props.submit && this.props.submit(data);
    }

    _renderAlert() {
        var {errors = []} = this.props;
        if (errors.length) {
            return errors.map((error, idx) => {
                return <Alert key={idx} bsStyle="danger">{error.message}</Alert>
            });
        }
    }

    onNewsletterChange(ev) {
        this.setState({newsletter: ev.target.checked});
    }

    onShowPasswordChange(ev) {
        this.setState({showPassword: ev.target.checked});
    }

    render() {
        return (
            <div className="container">
                <Helmet title="Register - ArtGeek" />

                <h1>Register</h1>

                {this._renderAlert()}

                <div className="row">
                    <div className="col-sm-6 col-sm-push-6">
                        <div className="hn-sign-up-promo-wrapper">
                            <div className="hn-sign-up-promo">
                                <h2>Sign Up for these features...</h2>
                                <ul>
                                    <li>Bookmarking - to capture exhibitions you want to save</li>
                                    <li>My List - to review & manage your personal list of exhibitions</li>
                                    <li>An occasional <strong className="logo-sneakpeek hn-small hn-light"><b className="sr-only">SneakPeek</b></strong> of our Editors' Picks <br/><small>(you can opt-out at any time)</small></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit} className="col-sm-6 col-sm-pull-6">
                        <FormGroup controlId="email"  validationState={validationState(this.props, "email")}>
                            <ControlLabel>Email</ControlLabel>
                            <div>
                                <FormControl
                                    type="text"
                                    ref="email"
                                />

                                <ValidationHint name="email" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <FormGroup controlId="username" validationState={validationState(this.props, "username")}>
                            <ControlLabel>Username</ControlLabel>
                            <div>
                                <FormControl
                                    type="text"
                                    ref="username"
                                    autoComplete="off"
                                    onChange={this.handleLookup}
                                />

                                <ValidationHint name="username" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <FormGroup controlId="password" validationState={validationState(this.props, "password")}>
                            <ControlLabel>Password</ControlLabel>
                                <Checkbox className="uiw-checkbox hn-show-password"
                                          checked={this.state.showPassword}
                                          onChange={this.onShowPasswordChange}
                                          title="Toggle to show/hide password">
                                    Show Password
                                </Checkbox>
                            <div>
                                <Password value={this.state.password}
                                          id="password" className="form-control"
                                          ref="password"
                                          onChange={this.handlePasswordChange}
                                          show={this.state.showPassword} />

                                <ValidationHint name="password" fieldErrors={this.props.fieldErrors} />
                            </div>
                        </FormGroup>
                        <Checkbox className="uiw-checkbox hn-subscribe-2-sneakpeak"
                                  name="newsletter" checked={this.state.newsletter}
                                  onChange={this.onNewsletterChange}>
                            <small>I prefer not to receive an occasional SneakPeek</small>
                        </Checkbox>
                        <FormGroup>
                            <div>
                                <button type="submit" className="btn btn-primary btn-block btn-lg">Register</button>
                            </div>
                        </FormGroup>
                        <p><small>By signing up, you agree to our <a href="terms" target="_blank">Terms and Conditions</a></small></p>
                    </form>
                </div>
            </div>
        )
    }
}

Register.defaultProps = {
    errors: [],
    fieldErrors: {}
};

const mapStateToProps = (state) => {
    var mappings = {
            errors: state.auth.errors || [],
            fieldErrors: Object.assign({}, state.auth.fieldErrors || {}),
            registered: state.auth.registeredState
        };

    if (state.auth.usernameTaken) {
        mappings.fieldErrors.username = {state: "error", message: "username already taken"};
    }

    return mappings;
};

const mapDispatchToProps = (dispatch) => {
    return {
        lookup: (q) => {
            dispatch(lookupUsername(q))
        },

        submit: (data) => {
            const fieldErrors = {};
            const user = data.user;
            required(fieldErrors, user, "email");
            required(fieldErrors, user, "username");
            required(fieldErrors, user, "password");

            dispatch({
                type: "REGISTER_FIELD_ERRORS",
                errors: [],
                fieldErrors
            });

            if (Object.keys(fieldErrors).length > 0) {
                return
            }

            dispatch(register(data))
                .then((action) => {
                    // if the register action was a success, dispatch a react-router-redux action to redirect to the
                    // login page...
                    if (action && (action.type === "REGISTER_SUCCESS")) {
                        dispatch(push("/login"));
                    }

                    return action;
                });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Register)
