import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ErrorHandler from "./ErrorHandler";
import { Footer, Header } from "./home";
import GoogleAdsenseProvider from "../google/GoogleAdsenseProvider";
import ScrollToTop from "../uiw-widgets/scrolltotop";

export const App = ({adSenseEnabled, children}) => (
    <GoogleAdsenseProvider>
        <div className={classnames({"goog-adsense": adSenseEnabled})}>
            <ErrorHandler>
                <Header />
                <div className="container-fluid">
                    {children}
                </div>
                <ScrollToTop />
                <Footer />
            </ErrorHandler>
        </div>
    </GoogleAdsenseProvider>
);

App.propTypes = {
    adSenseEnabled: PropTypes.bool
};

App.defaultProps = {
    adSenseEnabled: false
};

const mapStateToProps = (state) => {
    return {
        adSenseEnabled: !!state.google.adSense.enabled
    };
};

export default connect(mapStateToProps)(App);
