import PropTypes from "prop-types";
import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import AmazonProductWidget from "./AmazonProductWidget";
import AmazonProductsList from "./AmazonProductsList";

export default class RelatedProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {showAmazonProducts: false};
        this._toggleAmazonProducts = this._toggleAmazonProducts.bind(this);
    }

    static propTypes = {
        affiliateId: PropTypes.string.isRequired,
        amazonLinks: PropTypes.array.isRequired
    }

    _renderMoreButton() {
        const {affiliateId, amazonLinks} = this.props;
        return (
            <button type="button"
                    className="hn-store-link btn btn-default btn-block"
                    onClick={this._toggleAmazonProducts}>
                More Amazon Products
            </button>
        );
    }

    _toggleAmazonProducts() {
        this.setState({showAmazonProducts: !this.state.showAmazonProducts});
    }

    render() {
        const {affiliateId, amazonLinks} = this.props;
        const amazonLink = amazonLinks[0];

        if (!amazonLink) {
            return null;
        }

        return (
            <div className="hn-featured-products">
                <h2 className="hn-featured-products-hd">Related Products</h2>

                <div className="hn-featured-book">
                    {amazonLink && <AmazonProductWidget affiliateId={affiliateId} asin={amazonLink.identifier} />}
                </div>

                {(amazonLinks.length > 1) && this._renderMoreButton()}

                <Modal bsSize="lg" show={this.state.showAmazonProducts} onHide={this._toggleAmazonProducts}>
                    <Modal.Header closeButton>
                      <Modal.Title>Related Products</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AmazonProductsList affiliateId={affiliateId} products={amazonLinks} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
