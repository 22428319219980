import { addClass, removeClass } from "dom-helpers/class";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ErrorPage from "./ErrorPage";
import { authFetch } from "./auth";

export class ErrorHandler extends Component {
    constructor(props) {
        super(props);

        this.state = {hasError: false};
    }

    static propTypes = {
        location: PropTypes.shape({
            path: PropTypes.string,
            query: PropTypes.string,
            hash: PropTypes.string
        }),
        notificationsEnabled: PropTypes.bool,
        notify: PropTypes.func
    };

    static defaultProps = {
        notificationsEnabled: false
    };

    componentDidCatch(error, info) {
        this.setState({hasError: true});


        // `error` is the original error that caused the problem. `info.componentStack` is the component stacktrace.
        if (this.props.notificationsEnabled) {
            const { location={}, notify } = this.props;
            const uri = `${location.path}${location.query ? `?${location.query}` : ''}${location.hash ? `#${location.hash}` : ''}`;

            notify && notify({
                text: ":scream_cat: An error has occurred.",
                attachments: [{
                    title: "uri",
                    color: "#FF0000",
                    text: uri
                }, {
                    title: "Stacktrace",
                    color: "#FF0000",
                    text: error.stack.toString()
                }, {
                    title: "React Component Stack",
                    color: "#FF0000",
                    text: info.componentStack
                }]
            });
        }
    }

    componentDidUpdate() {
        if (this.state.hasError) {
            addClass(document.body, "ag-site-error");
        } else {
            removeClass(document.body, "ag-site-error");
        }
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    location: {
        path: state.routing.locationBeforeTransitions.pathname,
        query: state.routing.locationBeforeTransitions.search,
        hash: state.routing.locationBeforeTransitions.hash
    },
    notificationsEnabled: !!state.home.errorNotificationsEnabled
});

const mapDispatchToProps = (dispatch) => ({
    notify(notification) {
        return dispatch(authFetch("/api/monitor/notify", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(notification),
        }));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
