import { authFetch } from "../auth";
import { getExhibitions, museumsForExhibitions } from "../exhibition/ExhibitionActions";
import { getMuseums } from "../museum/MuseumActions";

/**
 * Get a hash of records, keyed by _id.
 *
 * @param records {Array} an array of records.
 * @return {Object} a hash of the records, keyed by _id.
 */
function hashById(records) {
    return records.reduce((hash, record) => {
            hash[record._id] = record;
            return hash;
        }, {});
}

/**
 * Convert a JSON fuzzy date to a proper fuzzy date object with real Date `start` and `end` properties.
 *
 * @param fuzzyDate {Object} the fuzzy date object.
 * @return {Object} the proper fuzzy date object.
 */
const fuzzyDateFromJson = (fuzzyDate) => {
    if (!fuzzyDate) {
        return null;
    }

    const result = {type: fuzzyDate.type};
    result.start = fuzzyDate.start && new Date(fuzzyDate.start);
    result.end = fuzzyDate.end && new Date(fuzzyDate.end);
    return result;
};

export function frontPage() {
    return (dispatch) => {
        return dispatch(authFetch("/api/config/frontPage?rest=false"))
            .then(response => {
                return response.json();
            })
            .then(json => {
                json.carouselMuseums || (json.carouselMuseums = []);

                // normalise `features` if `featuredExhibitions` was provided.
                if (json.featuredExhibitions) {
                    json.features = json.featuredExhibitions.map(exhibitionId => ({type: "exhibition", id: exhibitionId}));
                } else if (!json.features) {
                    json.features = [];
                }

                // extract a set of exhibitions and museums we need to load.
                const { exhibitionIds, museumIds } = json.features.reduce((result, feature) => {
                    if (feature && feature.type === "exhibition") {
                        result.exhibitionIds.push(feature.id);
                    }
                    else if (feature && feature.type === "museum") {
                        result.museumIds.push(feature.id);
                    }
                    return result;
                }, {exhibitionIds: [], museumIds: [...json.carouselMuseums]});

                const museumsPromise = museumIds.length ? dispatch(getMuseums(museumIds)) : [];

                const exhibitionsPromise = exhibitionIds.length 
                    ? dispatch(getExhibitions(exhibitionIds))
                        .then(exhibitions => {
                            // fetch any musuems referenced by the exhibitions.
                            return dispatch(museumsForExhibitions(exhibitions))
                                .then(museums => {
                                    // assemble a hashes of the exhibtions and museums, by id.
                                    return {
                                        exhibitions: hashById(exhibitions),
                                        museums: museums
                                    };
                                });
                        })
                    : Promise.resolve({exhibitions: {}, museums: {}});

                return Promise.all([museumsPromise, exhibitionsPromise])
                    .then(([museums, exhibitions]) => {
                        const carouselMuseums = json.carouselMuseums.map(museumId => {
                            return museums[museumId];
                        });

                        dispatch({
                            type: "CAROUSEL_MUSEUMS",
                            data: { museums: carouselMuseums }
                        });

                        const features = json.features.map(feature => {
                            if (feature && feature.type === "exhibition") {
                                const exhibition = exhibitions.exhibitions[feature.id];
                                const museums = exhibitions.museums;
                                if (!exhibition) {
                                    return null;
                                }
    
                                const events = (exhibition.events || []).map((event) => {
                                    return {
                                        ...event,
                                        startDate: fuzzyDateFromJson(event.startDate),
                                        endDate: fuzzyDateFromJson(event.endDate),
                                        museum: museums[event.museumId]
                                    };
                                });
    
                                return {type: "exhibition", id: exhibition._id, ...exhibition, events};    
                            } else if (feature && feature.type === "museum") {
                                const museum = museums[feature.id];
                                return {type: "museum", id: museum._id, ...museum};
                            }
                            return null;
                        });

                        // although data.museums contains more museum records than necessary, as they're only used
                        // for lookup, it's not an issue, just pass it straight through.
                        dispatch({
                            type: "FEATURED_EXHIBITIONS",
                            featuredExhibitions: features
                        });

                        dispatch({
                            type: "HOME_SNIPPETS",
                            data: json.snippets
                        });
                    });
            })
            .catch(err => {
                // TODO: dispatch some error action.
                console.error(err);
            });
    }
}
