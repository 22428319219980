import {addClass, removeClass} from "dom-helpers/class";
import events from "dom-helpers/events";
import query from "dom-helpers/query";
import {isBrowser} from "../common";

const NOOP = () => {};

export let installBodyClass = NOOP;
export let onScrollTopChange = NOOP;
export let offScrollTopChange = NOOP;

// if we're running in a browser environment, we'll replace the no-op functions with the proper implementation.
if (isBrowser()) {
    let atTop = query.scrollTop(window) === 0;
    const handlers = [];

    const toggle = () => {
        atTop = !atTop;
        trigger();
    };

    /**
     * Trigger any handlers interested in the isTop state change.
     */
    const trigger = () => {
        const arg = {isTop: atTop, top: query.scrollTop(window)};
        for (const handler of handlers) {
            handler(arg);
        }
    }

    /**
     * Hook the `scroll` event on the window object. This is used to manage the `isTop` state. Limiting the invocation
     * of any handlers to those instances where the `scrollTop` becomes 0 or positive from 0.
     */
    events.on(window, "scroll", function(ev) {
        const scrollY = query.scrollTop(window);
        if (scrollY > 0) {
            atTop && toggle();
        } else if (scrollY <= 0) {
            atTop || toggle();
        }
    });

    /**
     * Register a handler for responding to `isTop` state changes.
     *
     * @param handler {Function} the handler.
     * @param [immediate] {boolean} provide a false value to prevent the handler being immediately invoked.
     */
    onScrollTopChange = (handler, immediate) => {
        handlers.push(handler);

        if (immediate !== false) {
            const arg = {isTop: atTop, top: query.scrollTop(window)};
            handler(arg)
        }
    }

    /**
     * Unregister a handler responding to `isTop` state changes.
     *
     * @param handler {Function} the registered handler.
     * @return {boolean} true if handler was removed, false if not.
     */
    offScrollTopChange = (handler) => {
        const idx = handlers.indexOf(handler);
        if (idx !== -1) {
            handlers.splice(idx, 1);
            return true;
        }

        return false;
    }

    const bodyClassHandler = (event) => {
        const toggleClass = event.isTop ? removeClass : addClass;
        toggleClass(document.body, "uiw-has-scrolled");
    };

    /**
     * Install the handler that adds a class to the body element if the scroll position if off the top of the page. The
     * handler is only installed once so repeated calls to this will be fine.
     */
    installBodyClass = () => {
        const idx = handlers.indexOf(bodyClassHandler);
        if (idx === -1) {
            onScrollTopChange(bodyClassHandler);
        }
    }
}
