import { createReducer } from "redux-create-reducer";

const initialState = {
    byId: {},
    coverage: [],
    events: []
};

export const museumReducer = createReducer(initialState, {
    "CAROUSEL_MUSEUMS": (state, action) => {
        const museumsById = action.data.museums.reduce((result, museum) => {
            result[museum._id] = museum;
            return result;
        }, {});

        const byId = { ...state.byId, ...museumsById };

        return { ...state, byId };
    },
    "COVERAGE": (state, action) => {
        return { ...state, coverage: action.coverage };
    },
    "FETCHED_MUSEUMS": (state, action) => {
        const museums = action.museums.map(museum => {
            // bind an "heroImage" property onto each museum, this will be the URL for the hero
            // image for the museum.
            if (museum.images && museum.images[0]) {
                museum.heroImage = `/api/media/${museum.images[0]}`;
            }

            // NOTE: removed amazonLinks
            return { ...museum, amazonLinks: [] };
        });

        const byId = museums.reduce((result, museum) => {
            // ensure we have an `id` property.
            museum.id || (museum.id = museum._id);

            result[museum.id] = museum;
            return result;
        }, {...state.byId});

        return { ...state, byId}
    },
    "GET_MUSEUM": (state, action) => {
        const { events } = action;
        // NOTE: removed amazonLinks
        const museum = { ...action.museum, amazonLinks: [] };
        const byId = { ...state.byId, [museum._id]: museum };
        return { ...state, byId, events };
    },
    "GET_EXHIBITION": (state, action) => {
        const museums = Object.entries(action.data.museums || {}).reduce((result, [id, museum]) => {
            // NOTE: removed amazonLinks
            result[id] = { ...museum, amazonLinks: []};
            return result;
        }, {});

        return { ...state, byId: { ...state.byId, ...museums } };
    }
});
