import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class Password extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {value: props.value || ""};
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        onChange: PropTypes.func,
        show: PropTypes.bool,
        value: PropTypes.string
    };

    handleChange(ev) {
        const {onChange} = this.props;
        const {value} = ev.target;

        this.setState({value});
        onChange && onChange(value);
    }

    render() {
        const {value} = this.state;
        const {show, ...other} = this.props;
        const type = show ? "text" : "password";

        return (
            <input {...other} type={type} value={value} onChange={this.handleChange} />
        )
    }
}
