import { authFetch } from "../auth";

/**
 * Test if x is a JavaScript function.
 *
 * @param x {Any} the test subject.
 * @returns {boolean} true if x is a JavaScript function.
 */
function isFunction(x) {
    return typeof x === "function";
}

/**
 * Flatten the category hierarchy into a simple hash of categoryId/label values. This function is recursive when
 * flattening the sub-categories.
 *
 * @param results {Object} the target object.
 * @param categories {Array} the array of categories.
 * @param [prefix] {String} the key prefix.
 * @returns {Object} the target object.
 */
export function flatten(results, categories, prefix="") {
    categories.forEach(function(category) {
        var key = prefix + (prefix && "/") + category.key;
        results[key] = category.label;

        if (category.subs) {
            flatten(results, category.subs, key);
        }
    });

    return results;
}

/**
 * Get the category label. If there is no label for the given category, the category id is returned.
 *
 * @param categories {Object} the category id / label hash.
 * @param id {String} the category id.
 * @returns {String} the category label / id.
 */
export function categoryLabel(categories, id) {
    return categories[id] || id;
}

export function normalise(exhibition) {
    var categories = exhibition.categories || {},
        result = [];

    categories.medium && (result.push("medium/" + categories.medium));
    categories.origin && (result.push("origin/" + categories.origin));
    categories.period && (result.push("period/" + categories.period));
    categories.subject && (result.push("subject/" + categories.subject));
    categories.subMedium && (result.push("medium/" + categories.medium + "/" + categories.subMedium));
    categories.subOrigin && (result.push("origin/" + categories.origin + "/" + categories.subOrigin));
    categories.subPeriod && (result.push("period/" + categories.period + "/" + categories.subPeriod));
    categories.subSubject && (result.push("subject/" + categories.subject + "/" + categories.subSubject));

    return result;
}
