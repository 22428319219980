import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { currentEvent } from "./currentEvent";
import { EmptyCard } from "./EmptyCard";
import ExhibitionCard from "../exhibition/ExhibitionCard";
import { MuseumCard } from "../museum/MuseumCard";

export const FeaturedExhibitions = (props) => {
    const {categoryLabels, features} = props;
    return (
        <ul className="list-unstyled row">
            {features.map((featured, idx) => {
                let card = null;

                if (featured) {
                    if (featured.type === "exhibition") {
                        const { exhibition, event } = featured.entity;
                        card = <ExhibitionCard categoryLabels={categoryLabels}
                                               exhibition={exhibition}
                                               event={event} />
                    }
                    // NOTE: check here for featured.entity in case the museum is loading...
                    if (featured.type === "museum" && featured.entity) {
                        card = <MuseumCard museum={featured.entity} />
                    }
                }

                // TODO: render a proper placeholder.
                if (!card) {
                    card = <EmptyCard />
                }

                return (
                    <li key={idx} className="hn-results-li col-sm-6 col-md-4">{card}</li>
                );
            })}
        </ul>
    );
};

FeaturedExhibitions.propTypes = {
    categoryLabels: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
    const now = new Date();
    const features = (state.home.featured || []).map(feature => {
        if (!feature) {
            return null;
        }

        let entity = null;
        if (feature.type === "exhibition") {
            // TODO: consider that exhibition is not loaded
            entity = {                
                exhibition: state.exhibition.byId[feature.id],
                event: currentEvent(state.exhibition.byId[feature.id].events || [], now)
            }
        } else if (feature.type === "museum") {
            entity = state.museum.byId[feature.id];
        }

        return {
            type: feature.type,
            id: feature.id,
            entity
        };
    });

    return {
        categoryLabels: state.category.categories || {},
        features
    };
};

export default connect(mapStateToProps)(FeaturedExhibitions);
