import { ERRORS, REGISTERED, REGISTERING, RESET } from "./marketingReducer";
import { authFetch } from "../auth";

export function errors(opts) {
    return {
        type: ERRORS,
        errors: opts.errors || [],
        fieldErrors: opts.fieldErrors || {}
    };
}

export function register(email, payload={}) {
    return (dispatch) => {
        let requestBody = {email, ...payload};
        let conf = {
            method: "post",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(requestBody)
        };

        return dispatch(authFetch("/api/marketing/registration", conf))
            .then((response) => {
                if (response.status === 409) {
                    throw new Error(`${email} is already registered`);
                }
                else if (response.status !== 200) {
                    throw new Error("An error has occurred");
                }

                return response.json();
            });
    };
}

export function registered(email, props) {
    return {
        type: REGISTERED,
        email,
        props
    };
}

export function registering() {
    return {type: REGISTERING};
}

export function reset() {
    return {type: RESET};
}
