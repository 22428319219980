import {connect} from "react-redux";
import {event} from "../redux-ga";
import {OutboundLink as Link} from "./OutboundLink";

const mapDispatchToProps = (dispatch) => {
    return {
        track(category, action, label) {
            return dispatch(event(category, action, label));
        }
    }
};

export const OutboundLink = connect(undefined, mapDispatchToProps)(Link);
