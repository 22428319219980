import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "uiwidgets-react-legacy";
import { OutboundLink } from "../../react-redux-ga";

export const MuseumOutboundLink = ({href, id, name}) => (
    <p>
        <Tooltip tooltipText="Website opens in a new browser tab">
            <OutboundLink action="Museum"
                          category="OutboundLink"
                          className="hn-view-museum-url"
                          href={href}
                          label={`${id} - ${name}`}
                          target="_blank">
                <i className="fa fa-globe fa-fw hn-icon-left"></i> <span className="hn-icon-textblock">Museum Website</span>
            </OutboundLink>
        </Tooltip>
    </p>
);

MuseumOutboundLink.propTypes = {
    href: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};
