import React, {Component} from "react";
import Helmet from "react-helmet";
import {connect} from "react-redux";

export class Exhibitions extends Component {
    render() {
        return (
            <div className="container-fluid">
                <Helmet title="Exhibitions" />

                <div className="jumbotron row">
                    <h2>Exhibitions</h2>
                    <p>TODO - Browseable suggested for user Exhibitions go on this page, a continuation of any found on the home page.</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function(state, props) {
    return {};
};

export default connect(mapStateToProps)(Exhibitions)
