import React, {Component} from "react";
import SearchResultFacet from "./SearchResultFacet";

// labels for the facet headings.
const facetLabels = {
    "museumCity": "City",
    "museumStateName": "State",
    "museumTags": "Tags",
    "museumMetro": "Metro Area"
};

export const MAX_FACETS = 5;

export const hasSelectedChildren = (selected, facetKey) => {
    if (!selected) {
        return false;
    }

    const comparitor = facetKey + "/";
    for (const key of Object.keys(selected)) {
        if (key.startsWith(comparitor)) {
            return true;
        }
    }

    return false;
};

export default class SearchResultFacets extends Component {
    render() {
        const {onFacetClick} = this.props;
        const categoryLabels = this.props.categoryLabels || {};
        const facets = this.props.facets || [];
        const selected = this.props.selected || {};

        return (
            <div className="hn-facets">
                {facets.map((facet, idx) => {
                    return <SearchResultFacet key={idx}
                                              categoryLabels={categoryLabels}
                                              facet={facet}
                                              facetLabels={facetLabels}
                                              onFacetClick={onFacetClick}
                                              selected={selected} />
                })}
            </div>
        );
    }
}
