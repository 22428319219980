import PropTypes from "prop-types";
import React from "react";
import { MenuItem, Nav, NavDropdown, NavItem, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router";
import { push } from "react-router-redux";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../auth/AuthActions";

export function UserMenu(props) {
    let {user} = props;
    if (!user) {
        return (
            <LinkContainer to="/login">
                <NavItem eventKey={4}>Login</NavItem>
            </LinkContainer>
        )
    } else {
        return (
            <NavDropdown eventKey={5} title={user.username} id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                    <MenuItem eventKey={5.1}>Profile</MenuItem>
                </LinkContainer>
                <LinkContainer to="/bookmarks">
                    <MenuItem eventKey={5.2}>My Bookmark List</MenuItem>
                </LinkContainer>
                <MenuItem divider />
                <MenuItem eventKey={5.3} onSelect={props.logout}>Logout</MenuItem>
            </NavDropdown>
        )
    }
}

UserMenu.propTypes = {
    logout: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
                .then(() => {
                    dispatch(push("/"));
                });
        }
    }
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
