import PropTypes from "prop-types";
import React, {Component} from "react";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import { Tooltip } from "uiwidgets-react-legacy";
import championImage from "./ArtGeek_ChampionBadge_lg.png";
import { MuseumAssociations } from "./MuseumAssociations";
import { MuseumEvents } from "./MuseumEvents";
import { MuseumOutboundLink } from "./MuseumOutboundLink";
import { RelatedProducts } from "../amazon";
import { BookmarkToggle } from "../bookmark";
import { ExternalLinks } from "../externalLinks";
import { SearchResultsButton } from "../search";
import { ShareOn } from "../SocialShare";
import { OutboundLink } from "../../react-redux-ga";

export const MuseumBookmarkToggle = (props) => {
    const {museum} = props;
    if (!museum || !museum._id) {
        return null;
    }
    return <BookmarkToggle entityId={museum._id} entityType="Museum" title={museum.name} />
};

export class Museum extends Component {
    static propTypes = {
        amazonLinks: PropTypes.array,
        associations: PropTypes.array.isRequired,
        events: PropTypes.array,
        externalLinks: PropTypes.array,
        keywords: PropTypes.array.isRequired,
        museum: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        amazonLinks: [],
        externalLinks: []
    };

    render() {
        const {amazonLinks, associations, externalLinks, keywords, museum, title} = this.props;
        var events = this.props.events || [],
            heroImage = museum.heroImage,
            path = museum.path || `/museums/${museum._id}`,
            thisUrl = `${this.props.baseUrl}${path}`,
            backgroundContainer, imageFrame;

        const affiliateId = "eagleaffiliateid3-20";

        if (heroImage) {
            backgroundContainer = {backgroundImage: `url('${heroImage}')`};

            imageFrame = <div className="hn-view-exhibition-image-container">
                <div className="hn-exhibition-image-frame">
                    <img src={heroImage} />
                </div>
            </div>;
        }

        const meta = [
            {"property": "keywords", "content": keywords.join(",")},
            {"property": "og:title", "content": title},
            {"property": "og:type", "content": "place"},
            {"property": "og:image", "content": heroImage},
            {"property": "og:url", "content": thisUrl}
        ];

        return (
            <div className="hn-view-museum">
                <Helmet title={title} meta={meta} />

                <div className="jumbotron row">
                    <div className="hn-hero-museum-container">
                        <div className="hn-hero-museum-image" style={backgroundContainer}></div>
                    </div>
                    <div className="container">
                        {imageFrame}
                        <div className="hn-view-museum-names">
                            <h1>{museum.name}</h1>
                            <p className="hn-view-museum-names-subtitle">
                                <Tooltip tooltipText="Map opens in a new browser tab">
                                    <OutboundLink action="MuseumMap"
                                                  category="OutboundLink"
                                                  className="hn-view-museum-url"
                                                  href={`https://www.google.com.ph/maps/search/${museum.name}+${museum.streetAddress}+${museum.city}+${museum.state}+${museum.zipCode}`}
                                                  label={`${museum._id} - ${museum.name}`}
                                                  target="_blank">
                                        <i className="fa fa-fw fa-map-marker hn-icon-left"></i><span className="hn-icon-textblock">{museum.streetAddress}, {museum.city}, {museum.state} {museum.zipCode}</span>
                                    </OutboundLink>
                                </Tooltip>
                            </p>
                            <p><i className="fa fa-fw fa-phone hn-icon-left"></i><span className="hn-icon-textblock">{museum.phone}</span></p>
                            {museum.url ? <MuseumOutboundLink href={museum.url} id={museum._id} name={museum.name} /> : null}
                            <div className="hn-view-controls">
                                <div className="hn-view-nav">
                                    <SearchResultsButton><i className="fa fa-search fa-fw" aria-hidden="true"></i> Back to Search Results</SearchResultsButton>
                                </div>
                                <div className="hn-view-options">
                                    <MuseumBookmarkToggle museum={museum} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-9 hn-col-primary">

                            {events.length != 0 ?
                            <ul className="ag-toc text-center list-unstyled">
                                <li className="ag-toc-link">
                                    <Tooltip tooltipText="Scroll Down to View scheduled Exhibitions &amp; Dates">
                                        <a className="btn-block" href="#exhibitions"><i className="fa fa-arrow-circle-down" aria-hidden="true"></i> View scheduled <strong>Exhibitions &amp; Dates</strong></a>
                                    </Tooltip>
                                </li>
                            </ul> : null }

                            <div className="visible-xs-block visible-sm-block">
                                <MuseumAssociations associations={associations} />
                            </div>

                            <div className="hn-record-description" dangerouslySetInnerHTML={{__html: museum.content}} />

                            <ShareOn enabled={true} uri={thisUrl} />

                            {events.length > 0 ? <MuseumEvents events={events} /> : null}
                        </div>
                        <div className="col-md-3 hn-col-secondary">
                            <div className="hidden-xs hidden-sm">
                                <MuseumAssociations associations={associations} />
                            </div>
                            <RelatedProducts affiliateId={affiliateId} amazonLinks={amazonLinks} />
                            <ExternalLinks links={externalLinks} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function(state, ownProps) {
    const { museumId } = ownProps.params;
    const museum = state.museum.byId[museumId];

    const associations = [];

    if (museum.championExpiryDate) {
        const expiryDate = new Date(museum.championExpiryDate);
        const now = new Date();
        if (expiryDate > now) {
            associations.push({
                className: "ag-champion",
                imageSrc: championImage,
                text: "This museum is a <b>Champion</b>, helping make ArtGeek possible",
                title: "ArtGeek Champion"
            });
        }
    }

    (museum.associations || []).reduce((result, assocId) => {
        const assoc = state.assoc.hash[assocId];
        if (assoc) {
            result.push({
                ...assoc,
                className: "ag-association",
                title: "The North American Reciprocal Museum (NARM) Association"
            });
        }
        return result;
    }, associations);

    const amazonLinks = museum.amazonLinks || [];
    const externalLinks = (museum.externalLinks || []).filter(externalLink => externalLink.imageId);

    return {
        amazonLinks,
        associations,
        baseUrl: state.home.baseUrl,
        id: ownProps.params.museumId,
        events: state.museum.events,
        externalLinks,
        museum,
        title: `${museum.name} - ${museum.city} ${museum.state} - Museum - ArtGeek`,
        keywords: museum.tags || []
    };
};

export default connect(mapStateToProps)(Museum)
