import PropTypes from "prop-types";
import React from "react";

const AmazonProductWidget = (props) => {
    const {affiliateId, asin} = props;
    const src = '//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1' +
        `&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=${affiliateId}` +
        `&marketplace=amazon&region=US&placement=0615991424&asins=${asin}&linkId=SIQODEXA4LNMOY4Q` +
        '&show_border=true&link_opens_in_new_window=true';

    return (
        <div>
            <iframe style={{width: "120px", height: "240px"}}
                    marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src={src}></iframe>
        </div>
    );
};

AmazonProductWidget.propTypes = {
    affiliateId: PropTypes.string.isRequired,
    asin: PropTypes.string.isRequired
};

export default AmazonProductWidget;
