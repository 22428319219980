import React from "react";
import Helmet from "react-helmet";

export default function() {
    return (
        <div className="container-fixed hn-view-notfound">
            <Helmet title="Not Found" />

            <div className="hn-404">
                <h1>Page Not Found</h1>
                <div className="hn-404-sticker"></div>
                <div className="hn-404-base"></div>
            </div>
        </div>
    );
}
