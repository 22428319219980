import classnames from "classnames";
import insane from "insane";
import React from "react";
import { Tooltip } from "uiwidgets-react-legacy";
import { OutboundLink } from "../../react-redux-ga";

const sanitize = (text) => {
    const cleanText = insane(text, {allowedTags: ['b']});
    return {__html: cleanText};
};

export const MuseumAssociations = (props) => {
    return <div className="ag-endorsements">{props.associations.map((assoc, idx) => {
        const endorsementClass = classnames("ag-endorsement", assoc.className);
        const { _id: id, name } = assoc;
        return (
            <OutboundLink key={idx}
                          action="MuseumAssociation"
                          category="OutboundLink"
                          className={endorsementClass}
                          href={assoc.url}
                          label={`${id} - ${name}`}
                          target="_blank">
                <span className="ag-endorsement-logo">
                    <Tooltip tooltipText={assoc.title}>
                        <img src={assoc.imageSrc} />
                    </Tooltip>
                </span>
                <span className="ag-endorsement-text" dangerouslySetInnerHTML={sanitize(assoc.text)} />
            </OutboundLink>
        );
    })}</div>;
};
