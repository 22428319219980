import PropTypes from "prop-types";
import React from "react";

const AmazonProductImage = (props) => {
    const {affiliateId, asin} = props;
    const href = `//www.amazon.com/gp/product/${asin}/ref=as_li_tl?ie=UTF8&creative=9325&creativeASIN=${asin}&linkCode=as2&tag=${affiliateId}&linkId=2WZCXQG2L34GVCBJ`;
    const src = `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${asin}&Format=_SL110_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=${affiliateId}`;
    const weird = `//ir-na.amazon-adsystem.com/e/ir?t={affiliateId}&l=as2&o=1&a=${asin}`;

    return (
        <div>
            <a rel="nofollow" href={href} target="_blank">
                <img src={src} style={{border: "0"}} />
            </a>
            <img src={weird} width="1" height="1" alt="" style={{border: "none !important", margin: "0px !important"}} />
        </div>
    );
};

AmazonProductImage.propTypes = {
    affiliateId: PropTypes.string.isRequired,
    asin: PropTypes.string.isRequired
};

export default AmazonProductImage;
