import React from "react";

export default function CodeSent(props) {
    return (
        <div>
            <h1>Verification Code Sent</h1>
            
            <p>Please check your email for a new verification code/link.</p>
        </div>
    )
}
