import PropTypes from "prop-types";
import React, {Component} from "react";
import { findDOMNode } from "react-dom";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { resetPassword } from "../AuthActions"
import { ValidationHint, validationState } from "../../../uiw-widgets/LegacyFormValidation";

export class ResetPasswordForm extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static propTypes = {
        code: PropTypes.string.isRequired,
        errors: PropTypes.array,
        fieldErrors: PropTypes.object,
        submit: PropTypes.func.isRequired
    }

    static defaultProps = {
        errors: [],
        fieldErrors: {}
    }

    handleSubmit(ev) {
        ev.preventDefault();

        let password = findDOMNode(this.refs.password).value;
        let confirmPassword = findDOMNode(this.refs.confirmPassword).value;
        let {code} = this.props;

        this.props.submit(code, password, confirmPassword);
    }

    render() {
        return (
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <FormGroup controlId="password" validationState={validationState(this.props, "password")}>
                    <ControlLabel bsClass="col-sm-2 control-label">Password</ControlLabel>
                    <div className="col-sm-8">
                        <FormControl
                            type="password"
                            ref="password"
                            placeholder="Enter password"
                        />

                        <ValidationHint name="password" fieldErrors={this.props.fieldErrors} />
                    </div>
                </FormGroup>
                <FormGroup controlId="confirmPassword" validationState={validationState(this.props, "confirmPassword")}>
                    <ControlLabel bsClass="col-sm-2 control-label">Confirm Password</ControlLabel>
                    <div className="col-sm-8">
                        <FormControl
                            type="password"
                            ref="confirmPassword"
                            placeholder="Enter password"
                        />

                        <ValidationHint name="confirmPassword" fieldErrors={this.props.fieldErrors} />
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className="col-sm-offset-2 col-sm-10">
                        <button type="submit" className="btn btn-primary">Reset Password</button>
                    </div>
                </FormGroup>
            </form>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (code, password, confirmPassword) => {
            if (password !== confirmPassword) {
                dispatch({
                    type: "USER_RESET_PASSWORD_STATE",
                    fieldErrors: {
                        "confirmPassword": {
                            state: "error",
                            message: "mismatch"
                        }
                    }
                });
                return;
            }

            dispatch(resetPassword(code, password));
        }
    }
};

const mapStateToProps = (state) => {
    var resetPassword = state.auth.resetPassword || {};
    return {
        errors: resetPassword.errors || [],
        fieldErrors: resetPassword.fieldErrors || {}
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
