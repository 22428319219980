/**
 * Compose an array of url parameters for facets. For example an object like this:
 *    {"category": {"one": true, "two": true}}
 * would produce an array of url parameters like this:
 *    ["fa-category-one", "fa-category-two"]
 *
 * @param facets {Object} an object of facet keys and facet items.
 * @returns {Array} an array of facet parameters for the url.
 */
export function composeFacetParams(facets) {
    return Object.keys(facets).reduce(function(result, facetKey) {
        var facetItems = facets[facetKey];
        Object.keys(facetItems).forEach(function(facetItem) {
            result.push(["fa", facetKey, facetItem].join("-"));
        });
        return result;
    }, []);
}

/**
 * Decompose facets from a location query object. For example, an object like this:
 *    {"fa-category-one": true, "fa-category-one": true, "irrelevant-prop": 69}
 * would produce an object like this:
 *    {"category": {"one": true, "two": true}}
 *
 * @param query {Object} the location query object.
 * @returns {Object} a hash of facets.
 */
export function decomposeFacetParams(query) {
    return Object.keys(query).reduce(function(result, key) {
        if (key.startsWith("fa-")) {
            let bits = key.split("-"),
                facetKey = bits.shift() && bits.shift(),
                facetItemKey = bits.join("-");

            result[facetKey] || (result[facetKey] = {});
            result[facetKey][facetItemKey] = true;
        }
        return result;
    }, {});
}

/**
 * Build a url query string for the given search structure.
 *
 * @param search {Object} the search structure
 *   @param search.what {String} the "what" query string.
 *   @param search.where {String} the "where" query string.
 *   @param search.when {String} the "when" query string.
 *   @param search.facets {Object} a hierarchy of categories which values checked.
 */
export const buildSearchQueryString = (search) => {
    let result = [];

    if (search.what) {
        result.push("q=" + search.what);
    }
    if (search.where) {
        result.push("where=" + search.where);
    }
    if (search.when) {
        result.push("when" + search.when);
    }

    result = result.concat(composeFacetParams(search.facets || {}));

    return result.join("&");
};
