import React, {Component} from "react";
import { connect } from "react-redux";
import { verificationCode } from "../AuthActions";

export class CodeExpired extends Component {
    constructor() {
        super()

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        var {verifyId} = this.props;
        this.props.onClick && this.props.onClick(verifyId);
    }

    render() {
        return (
            <div>
                <h1>Verification Code Expired</h1>

                <p>Your single-use verification code has expired.</p>

                <div className="col-sm-offset-2 col-sm-10">
                    <button type="submit" className="btn btn-primary" onClick={this.onClick}>Request Verification Email</button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClick: (verifyId) => {
            dispatch(verificationCode({verifyId}));
        }
    }
};

export default connect(undefined, mapDispatchToProps)(CodeExpired);
