/**
 * Create an action instructing the auth middleware to make a request to the given URI, passing an authentication token
 * if we have one. You should be able to use this like the fetch API transparently.
 *
 * @param uri {String} the target URI.
 * @param [opts] {Object} options for the underlying fetch call.
 * @returns {Object} an action describing the fetch request to be made.
 */
export function authFetch(uri, opts) {
    return (dispatch) => {
        return dispatch({
                type: "@@auth/FETCH",
                uri: uri,
                opts: opts
            });
    }
}

export function loadProfile() {
    return (dispatch) => {
        return dispatch(authFetch("/api/user/profile"))
            .then((response) => {
                if (response.status === 401) {
                    return dispatch({type: "AUTH_FETCH_UNAUTHORIZED"})
                } else if (response.status === 200) {
                    return response.json()
                        .then((json) => {
                            return dispatch({
                                    type: "USER_PROFILE",
                                    profile: json
                                });
                        });
                }

                return response;
            });
    }
}

export function saveProfile(profile) {
    return (dispatch) => {
        var opts = {
                method: "PATCH",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(profile),
                credentials: "include"
            };

        return dispatch(authFetch("/api/user/profile", opts))
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                // TODO: dispatch an action to indicate the profile was saved.
            });
    }
}

export function changePassword(data) {
    return (dispatch) => {
        var opts = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(data),
                credentials: "include"
            };

        return dispatch(authFetch("/api/user/changePassword", opts))
            .then((response) => {
                if (response.status === 200) {
                    return {status: "ok"};
                }
                else if (response.status === 400) {
                    return response.json()
                        .then((json) => {
                            // TODO: handle 'else' case.
                            if (json.message === 'password_mismatch') {
                                dispatch({
                                        type: "USER_PROFILE_CHANGE_PASSWORD",
                                        errors: [],
                                        fieldErrors: {
                                            "oldPassword": {
                                                state: "error",
                                                message: "incorrect password"
                                            }
                                        }
                                    });

                                return {
                                    status: "err",
                                    fieldErrors: {
                                        "oldPassword": {
                                            state: "error",
                                            message: "incorrect password"
                                        }
                                    }
                                }
                            }
                        });
                }
            });
    }
}

export function login(username, password) {
    return (dispatch) => {
        var opts = {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({username, password})
            };

        return dispatch(authFetch("/api/auth/login", opts))
            .then((response) => {
                if (response.status === 401) {
                    // unauth
                    return dispatch({type: "AUTH_FAILURE"});
                }

                return response.json()
                    .then((body) => {
                        dispatch({
                                type: "@@auth/CALL_LOGIN",
                                token: body.token
                            });

                        return true;
                    });
            })
            .catch((err) => {
                dispatch({
                        type: "AUTH_ERROR",
                        message: err.message,
                        stack: err.stack
                    });

                return false;
            });

    }
}

export function logout() {
    return (dispatch) => {
        dispatch({type: "@@auth/CALL_LOGOUT"});
        return Promise.resolve(true);
    }
}

export function lookupUsername(q) {
    return (dispatch) => {
        return dispatch(authFetch(`/api/user/username/?q=${encodeURIComponent(q)}`, {credentials: "same-origin"}))
            .then((response) => {
                return response.json();
            })
            .then((body) => {
                return dispatch({
                        type: "REGISTER_USERNAME_LOOKUP",
                        taken: body.taken
                    });
            });
    }
}

export function register(data) {
    return (dispatch) => {
        let conf = {
            method: "post",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };

        return dispatch(authFetch("/api/user/register", conf))
            .then((response) => {
                if (response.status === 400) {
                    return response.json()
                        .then((body) => {
                            switch (body.message) {
                                case "user_already_exists":
                                    return dispatch({
                                            type: "REGISTER_FIELD_ERRORS",
                                            fieldErrors: {
                                                email: {
                                                    state: "error",
                                                    message: "User already exists"
                                                }
                                            }
                                        });
                                case "username_already_taken":
                                    return dispatch({
                                            type: "REGISTER_FIELD_ERRORS",
                                            fieldErrors: {
                                                username: {
                                                    state: "error",
                                                    message: "Username already taken"
                                                }
                                            }
                                        });
                            }
                        });
                }

                return response.json()
                    .then((body) => {
                        return dispatch({type: "REGISTER_SUCCESS"});
                    });
            })
            .catch((err) => {
                return dispatch({
                        type: "REGISTER_ERROR",
                        errors: [{
                            message: err.message,
                            stack: err.stack
                        }]
                    });
            });
    };
}

export function resetPassword(code, password) {
    return (dispatch) => {
        var conf = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({password}),
            };

        return dispatch(authFetch(`/api/user/resetPassword/${code}`, conf))
            .then((response) => {
                if (response.status === 404) {
                    return dispatch({
                            type: "USER_RESET_PASSWORD_STATE",
                            status: "code_not_found",
                            fieldErrors: {
                                "code": {
                                    state: "error",
                                    message: "Code not found"
                                }
                            }
                        });
                } else if (response.status === 400) {
                    return dispatch({
                            type: "USER_RESET_PASSWORD_STATE",
                            status: "code_expired"
                        });
                } else if (response.status === 200) {
                    return dispatch({
                            type: "USER_RESET_PASSWORD_STATE",
                            status: "password_changed"
                        });
                }

                return response.json()
                    .then((json) => {
                        return dispatch({
                                type: "USER_RESET_PASSWORD_STATE",
                                errors: [json]
                            });
                    });
            });
    }
}

export function resetPasswordCode(opts) {
    return (dispatch) => {
        var conf = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(opts)
            };

        return dispatch(authFetch("/api/user/resetPassword", conf))
            .then((response) => {
                if (response.status === 404) {
                    return dispatch({
                            type: "USER_RESET_PASSWORD_STATE",
                            status: "error",
                            fieldErrors: {
                                "email": {
                                    state: "error",
                                    message: "User account not found"
                                }
                            }
                        });
                } else if (response.status === 200) {
                    return dispatch({
                            type: "USER_RESET_PASSWORD_STATE",
                            status: "code_sent"
                        });
                }

                return response.json()
                    .then((json) => {
                        return dispatch({
                                type: "USER_RESET_PASSWORD_STATE",
                                errors: [json]
                            });
                    });

            })
            .catch((err) => {
                return dispatch({
                        type: "USER_RESET_PASSWORD_STATE",
                        errors: [{message: "An unexpected error occurred"}]
                    });
            });
    }
}

export function verificationCode(obj) {
    return (dispatch) => {
        let conf = {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(obj)
            };

        return dispatch(authFetch("/api/user/verificationCode", conf))
            .then((response) => {
                if (response.status == 404) {
                    // email not found.
                    return dispatch({
                            type: "AUTH_VERIFY_ERRORS",
                            errors: [],
                            fieldErrors: {
                                email: {
                                    state: "error",
                                    message: "User account not found"
                                }
                            }
                        });
                }

                return dispatch({
                        type: "AUTH_VERIFY_STATE",
                        state: "code_sent",
                        errors: [],
                        fieldErrors: {}
                    });
            });
    };
}

export function verify(verifyId) {
    return (dispatch) => {
        return dispatch(authFetch(`/api/user/verify/${verifyId}`))
            .then((response) => {
                if (response.status == 404) {
                    // verification code not found.
                    return dispatch({
                            type: "AUTH_VERIFY_STATE",
                            state: "not_found",
                            errors: [],
                            fieldErrors: {}
                        });
                } else if (response.status === 400) {
                    // verification code expired.
                    return dispatch({
                            type: "AUTH_VERIFY_STATE",
                            state: "code_expired",
                            errors: [],
                            fieldErrors: {}
                        });
                } else if (response.status === 200) {
                    // verification successful
                    return dispatch({
                            type: "AUTH_VERIFY_STATE",
                            state: "success",
                            errors: [],
                            fieldErrors: {}
                        });
                }

                return response.json()
                    .then((json) => {
                        // error
                        return dispatch({
                                type: "AUTH_VERIFY_ERRORS",
                                errors: [json]
                            });
                    });
            })
            .catch((err) => {
                return dispatch({
                        type: "AUTH_VERIFY_ERRORS",
                        errors: [{message: "unexpected_error"}]
                    });
            });
    };
}
