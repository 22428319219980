import PropTypes from "prop-types";
import React, {Component} from "react";
import { connect } from "react-redux";
import CodeSent from "./CodeSent";
import RequestForm from "./RequestForm";

/**
 * This component has the responsibility of delegating to the correct
 * ResetPassword component, depending on the current state of ResetPassword.
 */
export class ResetPassword extends Component {
    static propTypes = {
        init: PropTypes.func.isRequired,
        status: PropTypes.string
    }

    componentWillMount() {
        this.props.init();
    }

    render() {
        switch(this.props.status) {
            case "code_sent":
                return <CodeSent />
            default:
                return <RequestForm />
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        init: function() {
            dispatch({
                type: "USER_RESET_PASSWORD_STATE",
                status: undefined
            });
        }
    }
};

const mapStateToProps = (state, props) => {
    var resetPassword = state.auth.resetPassword;

    return {
        code: props.params.code,
        status: resetPassword.status
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
