import { createMemoryHistory, match } from "react-router";
import { authFetch } from "./auth";

/**
 * Assemble a component hierarchy based on a sequential array of matched components.
 *
 * @param renderProps {Object} the matched renderProps.
 *   @param renderProps.components {Array} an array of matched components
 * @param location {Object} the original location object.
 * @returns {Object} the top-level route component.
 */
function assemble(renderProps, location) {
    let topComponent = {};
    let currentComponent = topComponent;

    for (let Component of renderProps.components) {
        if (Component) {
            if (currentComponent.component) {
                currentComponent.childRoutes = [{}];
                currentComponent = currentComponent.childRoutes[0];
            }

            currentComponent.component = Component;
        }
    }

    currentComponent.path = location.pathname;

    return topComponent;
}

export default ({dispatch}, routes) => {
    return {
        /**
         * An asynchronous implementation of the getChildRoutes function. This makes a http request to the backend to
         * determine if the the page is valid or not, if it is, the route configuration is generated and returned.
         *
         * @param partialNextState {Object} the react-router location object.
         * @param cb {Function} a callback to invoke with the child routes.
         */
        getChildRoutes: function(partialNextState, cb) {
            const {location} = partialNextState;

            // TODO: how do we use an absolute URI here?
            dispatch(authFetch(`/api/slug/?srcPath=${encodeURIComponent(location.pathname)}`))
                .then((response) => {
                    return response.json()
                })
                .then((json) => {
                    var results = json.results || [];

                    // if there are no results, then we have no matching route.
                    if (results.length === 0) {
                        cb(null, []);
                        return;
                    }

                    // we've found a matching url, so now we need to render the correct stuff. This is more difficult that
                    // I'd like. We need to handle this differently on the server than on the client.
                    let loc = results[0].targetPath;

                    // create a memory history to use for the next match, we don't want to affect the main history
                    // used by the rest of the application.
                    let history = createMemoryHistory(loc);
                    match({routes, location: loc, history}, (err, redirect, renderProps) => {
                        // TODO: handle the null renderProps case.

                        // here, we use the onEnter hook to transplant the renderProps from the 'internal' match we
                        // just did, to the renderProps used by the top-level match. This will make any params
                        // extracted from this match available on the top-level match.
                        cb(null, {
                            onEnter: (nextState, replace) => {
                                Object.assign(nextState.params, renderProps.params);
                            },
                            childRoutes: [assemble(renderProps, location)]
                        });
                    });
                })
                .catch((err) => {
                    // TODO: handle the error case.
                });
        }
    }
}
