import { composeFacetParams } from "./SearchUtils";
import { parse } from "./SearchInputWhenParser";
import { authFetch } from "../auth";

const DEFAULT_PAGE_SIZE = 12;

export function lookupLocation(q) {
    return (dispatch) => {
        // TODO: rather than lowercasing here, should we be lower-casing at the service side?
        const url = `/api/search/findLocation?q=${encodeURIComponent(q.toLowerCase())}`;

        return dispatch(authFetch(url))
            .then((response) => {
                return response.json();
            });
    };
}

export function resultListLoading(isLoading) {
    return {
        type: "SEARCH_RESULTS_LOADING",
        isLoading: isLoading
    };
}

export function resultListStyle(style) {
    return {
        type: "SEARCH_RESULT_LIST_STYLE",
        style: style
    };
}

export function mobileFacetDisplay(state) {
    return {
        type: "SEARCH_FACET_MOBILE_DISPLAY",
        state: state
    };
}

export function search(opts = {}) {
    return (dispatch) => {
        var what = opts.what || "",
            where = opts.where || "",
            facets = opts.facets || {},
            facetParams = composeFacetParams(facets),
            url;

        url = `/api/search?q=${encodeURIComponent(what)}`;

        if (opts.when) {
            // parse the "when" text box and assign the value to both the start and end dates. If the text was a range,
            // then have to drill down the start and end branches to resolve the true start and end extremities. If it is
            // a single date, then the start and end dates need to be the same anyway...
            let startDate = parse(opts.when);
            let endDate = startDate;

            // resolve the start branch until we have a single date...
            while (startDate && startDate.start && startDate.start.type) {
                startDate = startDate.start;
            }

            if (startDate) {
                url+= "&start=" + startDate.start.getTime();
            }

            // resolve the end branch until we have a single date...
            while (endDate && endDate.end && endDate.end.type) {
                endDate = endDate.end;
            }

            if (endDate) {
                url+= "&end=" + endDate.end.getTime();
            }
        }

        if (opts.where) {
            url+= "&where=" + encodeURIComponent(where);
        }

        if (opts.offset) {
            url+= "&o=" + opts.offset;
        }

        url += "&l=" + (opts.pageSize || DEFAULT_PAGE_SIZE);

        if (facetParams.length) {
            url += "&" + facetParams.join("&");
        }

        return dispatch(authFetch(url))
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                result.hits = result.hits.map(function(hit) {
                    let document = hit.document;
                    if (document.images && document.images[0]) {
                        document.heroImage = `/api/media/${document.images[0]}`;
                    }
                    if (document.museumImages && document.museumImages[0]) {
                        document.museumHeroImage = `/api/media/${document.museumImages[0]}`;
                    }
                    return hit;
                });

                return result;
            })
            .then((result) => {
                return dispatch({
                        type: "SEARCH_RESULTS",
                        offset: opts.offset || 0,
                        result: {...result, query: opts}
                    });
            })
            .catch((err) => {
                return dispatch({
                        type: "ERROR",
                        message: err.message,
                        stack: err.stack
                    });
            });
    };
}
