import PropTypes from "prop-types";
import React, {Component} from "react";
import Helmet from "react-helmet";
import {Link} from "react-router";
import {connect} from "react-redux";
import {format} from "uiwidgets-datetime";
import {Tooltip} from "uiwidgets-react-legacy";
import warning from "warning";
import {getExhibition} from "./ExhibitionActions";
import BookmarkToggle from "./ExhibitionBookmarkToggle";
import { ExhibitionOutboundLink } from "./ExhibitionOutboundLink";
import {ShareOn} from "../SocialShare";
import {RelatedProducts} from "../amazon";
import {SearchResultsButton} from "../search";
import TagGroup from "../tags/TagGroup";

const getEntityId = (exhibition, event) => {
    if (event._id) {
        return `${exhibition._id}/${event._id}`;
    }
    return `${exhibition._id}`;
};

export class Exhibition extends Component {
    static propTypes = {
        amazonLinks: PropTypes.array,
        baseUrl: PropTypes.string.isRequired,
        exhibition: PropTypes.object.isRequired,
        eventId: PropTypes.string,
        events: PropTypes.array,
        keywords: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        amazonLinks: [],
        events: []
    };

    _renderEvent(event, exhibitionId, exhibitionTitle) {
        var liClasses = ["hn-results-li", "col-sm-6", "col-md-4"],
            museum = this.props.museums[event.museumId],
            museumHref = museum && (museum.path || ("/museums/" + museum._id)),
            museumName = museum ? museum.name : "No specified location",
            museumCity = museum ? museum.city : "",
            museumState = museum ? museum.state : "";

        if (this.props.eventId === event._id) {
            liClasses.push("hn-state-active");
        }

        return <li key={event._id} className={liClasses.join(" ")}>
            <div className="hn-results-content">
                <div className="hn-results-summary">
                    <div className="hn-results-summary-bd">
                        <h5 className="hn-results-date text-muted">
                            <span className="hn-results-date-start">{format(event.startDate, {utc: true})}</span>
                            <span className="hn-results-date-dash"> - </span>
                            <span className="hn-results-date-end">{format(event.endDate, {utc: true})}</span>
                        </h5>
                        <div className="row hn-results-museum-info">
                            <p className="col-xs-6 hn-results-venue"><Link to={museumHref}>{museumName}</Link></p>
                            <p className="col-xs-6 hn-results-location text-right">{museumCity}, {museumState}</p>
                        </div>
                        {event.museumExhibitionLink ? <ExhibitionOutboundLink href={event.museumExhibitionLink} id={exhibitionId} title={exhibitionTitle} /> : null}
                    </div>
                </div>
            </div>
        </li>
    }

    render() {
        const { amazonLinks, baseUrl, eventId, events, exhibition, keywords, title } = this.props;
        const { artists, categories, tags } = exhibition;

        var event = (eventId ? events.find(event => (event._id === eventId)) : events[0]) || {},
            entityId = getEntityId(exhibition, event),
            museums = this.props.museums || {},
            museum = (event.museumId && museums[event.museumId]) || {},
            museumName = museum.name || "No specified location",
            museumHref = museum.path || `/museums/${museum._id}`,
            path = exhibition.path || (eventId ? `/exhibitions/${exhibition._id}/${eventId}` : `/exhibitions/${exhibition._id}`),
            thisUrl = `${baseUrl}${path}`,
            categoryLabels = this.props.categoryLabels || {},
            heroImage = museum.heroImage,
            affiliateId = "eagleaffiliateid3-20",
            backgroundContainer, heroImage, imageFrame;

        const outboundLink = event.museumExhibitionLink || exhibition.url;

        if (heroImage) {
            backgroundContainer = {backgroundImage: `url('${heroImage}')`};

            imageFrame = <div className="hn-view-exhibition-image-container">
                <div className="hn-exhibition-image-frame">
                    <img src={heroImage} />
                </div>
            </div>;
        }

        const meta = [
            {"property": "keywords", "content":  keywords.join(",")},
            {"property": "og:title", "content": title},
            {"property": "og:type", "content": "article"},
            {"property": "og:image", "content": heroImage},
            {"property": "og:url", "content": thisUrl}
        ];

        return (
            <div className="hn-view-exhibition">
                <Helmet title={title} meta={meta} />

                <div className="jumbotron row">
                    <div className="hn-hero-exhibition-container">
                        <div className="hn-hero-exhibition-image" style={backgroundContainer}></div>
                    </div>
                    <div className="container">
                        {imageFrame}
                        <div className="hn-view-exhibition-titles">
                            <h1>{exhibition.title}</h1>
                            <h2>{exhibition.subTitle}</h2>
                            {outboundLink ? <ExhibitionOutboundLink href={outboundLink} id={exhibition._id} title={exhibition.title} /> : null}
                            <div className="hn-view-controls">
                                <div className="hn-view-nav">
                                    <SearchResultsButton><i className="fa fa-search fa-fw" aria-hidden="true"></i> Back to Search Results</SearchResultsButton>
                                </div>
                                <div className="hn-view-options">
                                    <BookmarkToggle exhibition={exhibition} event={event} museum={museum} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-9 hn-col-primary">

                            <div className="hn-exhibition-current">
                                <div className="hn-results-content">
                                    <h5 className="hn-results-date">
                                        <span className="hn-results-date-start">{format(event.startDate, {utc: true})}</span>
                                        <span className="hn-results-date-dash"> - </span>
                                        <span className="hn-results-date-end">{format(event.endDate, {utc: true})}</span>
                                    </h5>
                                    <div className="row">
                                        <p className="hn-results-venue"><Link to={museumHref}><i className="fa fa-bank text-danger" aria-hidden="true"></i> <span>{museumName}</span></Link></p>
                                        <p className="hn-results-location">{museum.city}, {museum.state}</p>
                                    </div>
                                </div>
                            </div>

                            {events.length != 0 ?
                            <ul className="ag-toc text-center list-unstyled">
                                <li className="ag-toc-link">
                                    <Tooltip tooltipText="Scroll Down to View scheduled Exhibition Venues &amp; Dates">
                                        <a className="btn-block" href="#exhibitions"><i className="fa fa-arrow-circle-down" aria-hidden="true"></i> View scheduled Exhibition <strong>Venues &amp; Dates</strong></a>
                                    </Tooltip>
                                </li>
                            </ul> : null}

                            <div className="hn-record-description" dangerouslySetInnerHTML={{__html: exhibition.content}} />

                            <ShareOn enabled={true} uri={thisUrl} />

                            <div className="hn-tag-group">
                                <TagGroup artists={artists}
                                          categoryLabels={categoryLabels}
                                          categories={categories}
                                          tags={tags} />
                            </div>

                            <h2 className="hn-exhibition-event-hd" id="exhibitions" name="exhibitions">Exhibition Venues &amp; Dates</h2>
                            <div className="hn-results-list">
                                <ul className="list-unstyled hn-exhibition-events-list row">
                                    {events.length === 0 ?
                                        <li className="hn-results-li col-sm-6 col-md-4">
                                            <div className="alert alert-warning" role="alert">No scheduled events found</div>
                                        </li> : null}
                                    {events.map(event => this._renderEvent(event, exhibition._id, exhibition.title))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 hn-col-secondary">
                            <RelatedProducts affiliateId={affiliateId} amazonLinks={amazonLinks} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function(state, ownProps) {
    const { eventId, exhibitionId } = ownProps.params;
    const exhibition = state.exhibition.byId[exhibitionId];

    // build up keywords from tags and categories.
    const categoryLabels = state.category.categories || [];
    const categories = exhibition.categories.map(categoryId => categoryLabels[categoryId]);

    const artists = (exhibition.artists || []).map(artist => artist.fullName);

    const keywords = (exhibition.tags || []).concat(categories).concat(artists);

    const events = exhibition.events || [];
    const event = (eventId ? events.find(event => (event._id === eventId)) : events[0]) || {};
    const museums = events
        .map(event => ({event, museum: state.museum.byId[event.museumId]}))
        .reduce((result, {event, museum}) => {
            warning(museum, `unmatched museum "${event.museumId}" in exhibition "${exhibitionId}", event "${event._id}"`);
            museum && (result[museum._id] = museum);
            return result;
        }, {});

    const museum = (event.museumId && museums[event.museumId]);

    const titleCity = museum ? `${museum.city} ${museum.state} - `: '';
    const title = `${exhibition.title} - ${titleCity}Exhibition - ArtGeek`;

    const amazonLinks = exhibition.amazonLinks || [];

    return {
        amazonLinks,
        keywords,
        baseUrl: state.home.baseUrl,
        id: exhibitionId,
        categoryLabels,
        eventId,
        events,
        exhibition,
        museums,
        title
    };
};

export default connect(mapStateToProps)(Exhibition);
