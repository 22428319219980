import PropTypes from "prop-types";
import React from "react";

export function ValidationHint(props) {
    var fieldErrors = props.fieldErrors || {},
        name = props.name,
        fieldError = fieldErrors[name];

    if (fieldError && fieldError.state !== "success") {
        return (
            <p className="help-block hn-validation-hint">{fieldError.message}</p>
        );
    }

    return null;
}

ValidationHint.propTypes = {
    name: PropTypes.string.isRequired,
    fieldErrors: PropTypes.object
};

/**
 * A function to derive a value for the react-bootstrap "validationState" property of the FormGroup component.
 *
 * @param props {Object} the component props with a "fieldErrors" property.
 * @param field {String} the name of the field
 * @return {String} "success", "warning", "error" or undefined.
 */
export function validationState(props, field) {
    var fieldErrors = props.fieldErrors || {},
        field = fieldErrors[field] || {};

    return field.state;
}

// this.props.fieldErrors.name && this.props.fieldErrors.name.state

export function required(fieldErrors, data, fieldName) {
    if (!data[fieldName]) {
        fieldErrors[fieldName] = {
            state: "error",
            message: "required"
        }
    }
}
