import React, {Component} from "react";
import { Alert, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import {findDOMNode} from "react-dom";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router";
import { push } from "react-router-redux";
import { login } from "./AuthActions";

export class Login extends Component {
    constructor() {
        super()
        this.state = {username: "", password: ""};
    }

    handlePasswordChange(ev) {
        this.setState({password: ev.target.value});
    }

    handleUsernameChange(ev) {
        this.setState({username: ev.target.value});
    }

    handleSubmit(ev) {
        var {login, loginSuccess} = this.props,
            {username, password} = this.state;

        ev.preventDefault();

        login(username, password)
            .then((result) => {
                if (result !== true) {
                    this.setState({
                        error: result.message || "invalid username or password",
                        password: ""
                    });

                    // set the focus back to the `password` input.
                    const passwordEl = findDOMNode(this.refs.password);
                    passwordEl && passwordEl.focus();
                    return;
                }

                loginSuccess();
            });
    }

    _renderAlert() {
        if (this.state.error) {
            return (
                <Alert bsStyle="danger">{this.state.error}</Alert>
            )
        }
    }

    render() {
        return (
            <div className="container">
                <Helmet title="Login - ArtGeek" />

                <h1>Sign In</h1>

                {this._renderAlert()}
                <div className="row">
                    <form onSubmit={this.handleSubmit.bind(this)} className="col-sm-8">
                        <FormGroup controlId="username">
                            <ControlLabel>Username</ControlLabel>
                            <div>
                                <FormControl
                                    type="text" autoFocus
                                    value={this.state.username}
                                    onChange={this.handleUsernameChange.bind(this)}
                                />
                                <FormControl.Feedback />
                            </div>
                        </FormGroup>
                        <FormGroup controlId="password">
                            <ControlLabel>Password</ControlLabel>
                            <Link to="/forgot" tabIndex="-1" className="btn-link pull-right">Forgotten password?</Link>
                            <div>
                                <FormControl
                                    ref="password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange.bind(this)}
                                />
                                <FormControl.Feedback />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div>
                                <button type="submit"
                                        className="btn btn-primary"
                                        disabled={!this.state.username}>Sign In</button>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div>
                                Not registered? &nbsp; <Link to="/register" className="btn-link">Sign up for a New Account</Link>
                            </div>
                        </FormGroup>
                    </form>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password) => {
            return dispatch(login(username, password));
        },
        loginSuccess: () => {
            dispatch(push("/"));
        }
    }
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
