import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import { format } from "uiwidgets-datetime";

export const MuseumEvent = ({event}) => {
    const href = event.path || `/exhibitions/${event.exhibitionId}/${event.eventId}`;
    const subTitle = event.subTitle ? <span> &nbsp;<span className="sr-only">-</span> {event.subTitle}</span> : null;

    return (
        <li key={event.eventId} className="hn-results-li col-sm-6 col-md-4">
            <div className="hn-results-content">
                <h4 className="hn-results-summary-hd-bg">
                    <Link to={href}><strong>{event.title}</strong>{subTitle}</Link>
                </h4>
                <div className="hn-results-summary">
                    <div className="hn-results-summary-bd">
                        <h5 className="hn-results-date text-muted">
                            <span className="hn-results-date-start">{format(event.startDate, {utc: true})}</span>
                            <span className="hn-results-date-dash"> - </span>
                            <span className="hn-results-date-end">{format(event.endDate, {utc: true})}</span>
                        </h5>
                        <div className="hn-overview-wrapper">
                            <p className="text-muted hn-overview-summary">
                                {event.summary}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};

MuseumEvent.propTypes = {
    event: PropTypes.object.isRequired
};