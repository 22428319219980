import PropTypes from "prop-types";
import React from "react";
import {ShareButtons, ShareCounts} from "react-share";

const {FacebookShareButton, TwitterShareButton} = ShareButtons;
const {FacebookShareCount} = ShareCounts;

export const ShareOnFacebook = (props) => {
    const {uri} = props;
    return (
        <div className="hn-social hn-social-facebook">
            <FacebookShareButton url={uri}>
                <i className="fa fa-facebook-square"></i>
                <span className="hn-social-label sr-only">Facebook</span>
            </FacebookShareButton>
        </div>
    );
};

ShareOnFacebook.propTypes = {
    "uri": PropTypes.string.isRequired
};

export const ShareOnTwitter = (props) => {
    return (
        <div className="hn-social hn-social-twitter">
            <TwitterShareButton url={props.uri}>
                <i className="fa fa-twitter-square"></i>
                <span className="hn-social-label sr-only">Twitter</span>
            </TwitterShareButton>
        </div>
    );
};

ShareOnTwitter.propTypes = {
    "uri": PropTypes.string.isRequired
};

export const ShareOn = (props) => {
    const {enabled=true, uri} = props;

    if (!enabled) {
        return null;
    }

    return (
        <div className="hn-social-links">
            <h2>Share this</h2>
            <ShareOnFacebook uri={uri} />
            <ShareOnTwitter uri={uri} />
        </div>
    );
};

ShareOn.propTypes = {
    "uri": PropTypes.string.isRequired
};
