import PropTypes from "prop-types";
import React, {Component} from "react";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import BookmarksRow from "./BookmarksRow";

export class Bookmarks extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        bookmarks: PropTypes.array.isRequired
    };

    render() {
        const {bookmarks} = this.props;
        return (
            <div>
                <Helmet title="My Bookmark List - ArtGeek" />

                <div className="ag-view-heading hn-view-bookmarks">
                    <div className="jumbotron row">
                        <div className="container">
                            <div className="hn-view-profile-image-container">
                                <div className="hn-hero-titles">
                                    <h1>My Bookmark List</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <table className="table table-hover hn-bookmark-list">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Bookmark</th>
                                <th>Bookmarked Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookmarks.length
                                ? bookmarks.map((bookmark) => {
                                    return <BookmarksRow key={bookmark._id} bookmark={bookmark} />;
                                })
                                : <tr className="info"><td colSpan="3">No bookmarks</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        bookmarks: state.bookmark.list
    };
};

export default connect(mapStateToProps)(Bookmarks);
