import classNames from "classnames";
import React from "react";
import {ListGroup} from "react-bootstrap";
import SearchResultFacetValue from "./SearchResultFacetValue";
import CollapsedSearchResultFacetGroup from "./CollapsedSearchResultFacetGroup";
import {MAX_FACETS, hasSelectedChildren} from "./SearchResultFacets";

const sortByLabel = (a, b) => {
    if (a.label > b.label) {
        return 1;
    } else if (a.label < b.label) {
        return -1;
    }
    return 0;
};

const SearchResultFacetGroup = (props) => {
    const {categoryLabels, facetKey, onFacetClick, selected, showMore} = props;

    // where possible resolve labels for the categories.
    const facets = props.facets.map((facetItem) => {
        return {
            ...facetItem,
            label: categoryLabels[facetItem.key] || facetItem.key,
            hasSelectedChildren: hasSelectedChildren(selected[facetKey], facetItem.key)
        };
    });

    // sort by the label, so they appear in alphabetical order.
    facets.sort(sortByLabel);

    if (!showMore) {
        return <CollapsedSearchResultFacetGroup {...props} facets={facets} />
    }

    return (
        <ListGroup>
            {facets.map((facetItem, idx) => {
                return <SearchResultFacetValue key={idx}
                                               categoryLabels={categoryLabels}
                                               facetKey={facetKey}
                                               facetItem={facetItem}
                                               onFacetClick={onFacetClick}
                                               selected={selected}
                                               showMore={showMore} />
            })}
        </ListGroup>
    );
};

export default SearchResultFacetGroup;
