import React from "react";
import { Link } from "react-router";
import NewsletterForm from "./NewsletterForm";

export const Footer = (props) => (
    <footer>
        <div className="container">
            <div className="hn-footer-sitename">
                <h2>ArtGeek</h2>
            </div>
            <div className="row">
                <div className="col-xs-4 col-sm-3">
                    <h3>About ArtGeek</h3>
                    <ul className="list-unstyled">
                        <li>
                            <Link to="/about">About Us</Link>
                        </li>
                        <li>
                            <Link to="/aboutyou">About You</Link>
                        </li>
                        <li>
                            <Link to="/terms">Terms &amp; Conditions</Link>
                        </li>
                        <li>
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/disclosure">Affiliate Disclosure</Link>
                        </li>
                        <li>
                            <Link to="/cookies">Cookie Policy</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-xs-4 col-sm-3">
                    <h3>Support</h3>
                    <ul className="list-unstyled">
                        <li>
                            <Link to="/for-museums">Just for Museums</Link>
                        </li>
                        <li>
                            <Link to="/museums">Museum List</Link>
                        </li>
                        <li>
                            <Link to="/help">Help and FAQ</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-xs-4 col-sm-2 col-md-3">
                    <h3>Follow Us</h3>
                    <ul className="list-unstyled">
                        <li>
                            <a href={props.socialLinks.facebook} target="_blank"><i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i> Facebook</a>
                        </li>
                        <li>
                            <a href={props.socialLinks.twitter} target="_blank"><i className="fa fa-twitter-square fa-2x" aria-hidden="true"></i> Twitter</a>
                        </li>
                    </ul>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-3">
                    <NewsletterForm />
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-sm-6">
                    <small>
                        Designed by <a href="https://fizix.net" target="_blank">fizix</a>
                    </small>
                </div>
                <div className="col-sm-6 text-right">
                    <small>
                        © {props.dates.currentYear} Arts Advantage Publishing LLC. All rights reserved.
                    </small>
                </div>
            </div>
        </div>
    </footer>
);

Footer.defaultProps = {
    socialLinks: {
        facebook: "https://www.facebook.com/ArtGeekio-for-art-lovers-who-travel-116879431669041/",
        twitter: "https://twitter.com/artgeek_art"
    },
    dates: {
        currentYear: new Date().getFullYear()
    }
};

export default Footer;
