import {extractPayload, hasExpired} from "./JwtUtils";

export default function jwtAuthProvider(tokenStrategy) {
    var listeners = [];

    function triggerEvent(ev) {
        for (let fn of listeners) {
            fn(ev);
        }
    }

    return {
        addListener: function(fn) {
            listeners.push(fn);
        },

        removeListener: function(fn) {
            var idx = listeners.indexOf(fn);
            if (idx !== -1) {
                listeners.splice(idx, 1);
            }
        },

        isAuthenticated: function() {
            var payload = this.payload();
            if (payload) {
                if (hasExpired(payload)) {
                    this.logout();
                    return false;
                }
                return true;
            }

            return false;
        },

        login: function(token) {
            var currentToken = tokenStrategy.token();
            if (currentToken === token) {
                return;
            }

            tokenStrategy.token(token);
            triggerEvent({type: "login", token: token});
        },

        logout: function() {
            tokenStrategy.removeToken();

            triggerEvent({type: "logout"});
        },

        payload: function() {
            var token = tokenStrategy.token();
            if (!token) {
                return null;
            }
            return extractPayload(token);
        },

        token: tokenStrategy.token
    }
};
