import React from "react";
import { Route, IndexRoute } from "react-router";
import App from "./components/App";
import {Bookmarks, createRoutes as createBookmarkRoutes} from "./components/bookmark";
import {Exhibition, Exhibitions, routes as createExhibitionRoutes} from "./components/exhibition";
import {Home} from "./components/home";
import Page from "./components/page/Page";
import PageNotFound from "./components/PageNotFound";
import Search from "./components/search/SearchResults";
import {Login, Profile, Register, withPermission} from "./components/auth";
import {ResetPassword, ResetPasswordCode} from "./components/auth/reset";
import {Verify} from "./components/auth/verify";
import {createRoutes as createMuseumRoutes} from "./components/museum";
import createSlugRoutes from "./components/createSlugRoutes";

// imports for Home page
import {frontPage} from "./components/home/HomeActions";
import {getCategories} from "./components/categories/CategoryActions";

// imports for Search page
import deepEqual from "deep-equal";
import {search} from "./components/search/SearchActions";
import {decomposeFacetParams} from "./components/search/SearchUtils"

// imports for Profile page
import {loadProfile} from "./components/auth/AuthActions";

// imports for Verify page
import { verify } from "./components/auth/AuthActions";

// imports for Page page
import {byId} from "./components/page/PageActions";

const createRoutes = (store) => {
    const {dispatch, getState} = store;

    const bookmarkRoutes = createBookmarkRoutes(store);
    const exhibitionRoutes = createExhibitionRoutes(store);
    const museumRoutes = createMuseumRoutes(store);

    const routes = [{
        path: "/",
        component: App,
        indexRoute: {
            component: Home,
            onEnter: (nextState, replace, callback) => {
                Promise.all([dispatch(frontPage()), dispatch(getCategories())])
                    .then(() => callback());
            }
        },
        childRoutes: [{
            path: "forgot",
            indexRoute: {
                component: ResetPassword
            },
            childRoutes: [{
                path: "code",
                component: ResetPasswordCode
            }, {
                path: "code/:code",
                component: ResetPasswordCode
            }]
        }, {
            path: "login",
            component: Login
        }, {
            path: "profile",
            component: withPermission(Profile),
            onEnter: (nextState, replace, callback) => {
                // only attempt to load the profile if we have an auth token.
                const state = getState();
                if (state.auth.token) {
                    return dispatch(loadProfile())
                        .then(() => callback());
                }

                callback();
            }
        }, {
            path: "bookmarks",
            childRoutes: bookmarkRoutes
        }, {
            path: "register",
            component: Register
        }, {
            path: "search",
            component: Search,
            onEnter: (nextState, replace, callback) => {
                var reqQuery = nextState.location.query,
                    facets = decomposeFacetParams(reqQuery),
                    promises = [dispatch(getCategories())],
                    query;

                query = {what: reqQuery.q, when: reqQuery.when, where: reqQuery.where, facets};

                // if no criteria has been provided to filter the results AT ALL, refuse to do the query.
                if (!reqQuery.q && !reqQuery.when && !reqQuery.where && !Object.keys(facets).length) {
                    // dispatch an empty array for the results.
                    promises.push(Promise.resolve(dispatch({
                        type: "SEARCH_RESULTS",
                        offset: 0,
                        result: []
                    })));
                } else {
                    promises.push(dispatch(search({
                        what: reqQuery.q,
                        when: reqQuery.when,
                        where: reqQuery.where,
                        facets: facets
                    })));
                }

                Promise.all(promises).then(() => callback());
            }

        }, {
            path: "exhibitions",
            childRoutes: exhibitionRoutes
        }, {
            path: "museums",
            childRoutes: museumRoutes
        }, {
            path: "page/:pageId",
            component: Page,
            onEnter: (nextState, replace, callback) => {
                const {pageId} = nextState.params;
                dispatch(byId(pageId))
                    .then(() => {
                        callback();
                    });
            }
        }, {
            path: "verify",
            component: Verify
        }, {
            path: "verify/:verifyId",
            component: Verify,
            onEnter: (nextState, replace, callback) => {
                const {verifyId} = nextState.params;
                dispatch(verify(verifyId))
                    .then(() => {
                        callback();
                    });
            }
        }]
    }];

    const slugRoutes = createSlugRoutes(store, routes);

    return [...routes, slugRoutes, {
        path: "/",
        childRoutes: [{
            path: "*",
            component: PageNotFound
        }]
    }];
}

export { createRoutes };
